import { Component, OnInit, ViewChild } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../services/dataService.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ViewlinkComponent } from '../../components/viewlink/viewlink.component';
import { AppSettings } from '../../app-Settings';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
let ApiUrl = AppSettings.API_ENDPOINT;




export interface Project {
  Address: string | null;
  City: string;
  Client: string;
  ClientName: string | null;
  DealType: string;
  Id: string;
  Intersection: string;
  Latitude: number | null;
  Longitude: number | null;
  MSA: string | null;
  OXBlueCameraFeed: string | null;
  ProjectStatusString: string | null;
  Project_Image: string | null;
  Project_ImageVirtualUrl: string;
  Project_Status: string;
  ProposedOpening: Date | null;
  ProposedTo: Date | null;
  REC_Approval: Date | null;
  REC_Submitted: Date | null;
  SiteId: string | null;
  State: string;
  Status: boolean;
  Store: string | null;
  UpdateBy: string | null;
  UpdateDate: Date | null;
  Zip: string | null;
}


@Component({
  selector: 'app-projectlist',
  templateUrl: './projectlist.component.html',
  styleUrls: ['./projectlist.component.css']
})
export class ProjectlistComponent implements OnInit {
  users: any;
  settings: any;
  data: ServerDataSource
  projectList: Project[]
  ptype = 0;

  filteredList: Project[] = [];
  sortDirection: { [key: string]: boolean } = {};
  currentPage: number = 1;
  pageSize: number = 20;
  totalPages: number;

  constructor(private http: HttpClient, private dataService: DataService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) {

    this.route.params.subscribe(params => {
      if (params['ptype']) {
        this.ptype = params['ptype']
        if (this.ptype != 0) {
          this.data = new ServerDataSource(http, {
            //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
            endPoint: ApiUrl + 'SiteProfile/getProjectDataList?ProjectType=' + this.ptype,
            // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
            dataKey: 'data.data',
            totalKey: 'TotalCount',
            pagerLimitKey: 'RecordCount',
            pagerPageKey: 'PageNumber',
            filterFieldKey: '#field#',
            sortFieldKey: 'SortColumn',
            sortDirKey: 'SortOrder',
          });

        }

      }
    });



    this.settings = {
      columns: {
        Client: {
          title: 'Client',
          type: 'custom',
          renderComponent: ViewlinkComponent
        },
        City: {
          title: 'City'
        },
        State: {
          title: 'State'
        },
        Intersection: {
          title: 'Intersection'
        },
        DealType: {
          title: 'Deal Type',
        },
        Project_Status: {
          title: 'Status',
          filter: false
        },
      },

      actions: {
        edit: false,
        add: false,
        delete: false,
        // custom: [
        //   { name: 'Edit', title: `<i  class="fa fa-edit"></i>` }

        // ],
        position: 'left', // left|right
      },
      pager: {
        display: true,
        perPage: 300
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };

  }

  ngOnInit() {
    this.bindProjectList();

    // localStorage.setItem("projectId", "0");
  }
  newProject() {
    var previousList = {};
    if (this.ptype == 1) {
      previousList = {
        listType: "Current Projects",
        nav: "projects/1"
      }
    } else if (this.ptype == 2) {
      previousList = {
        listType: "Complete Projects",
        nav: "completeprojects/2"
      }
    } else {
      previousList = {
        listType: "Dead Projects",
        nav: "deadprojects/3"
      }
    }

    localStorage.setItem("projectType", JSON.stringify(previousList));

    localStorage.setItem("projectId", "0");
    this.router.navigate(['/projectdetail'])

  }

  bindProjectList() {
    const clientFilter = (document.getElementById('Client') as HTMLInputElement).value.toLowerCase();
    const cityFilter = (document.getElementById('City') as HTMLInputElement).value.toLowerCase();
    const stateFilter = (document.getElementById('State') as HTMLInputElement).value.toLowerCase();
    const intersectionFilter = (document.getElementById('Intersection') as HTMLInputElement).value.toLowerCase();
    const dealFilter = (document.getElementById('Deal') as HTMLInputElement).value.toLowerCase();
    const statusFilter = (document.getElementById('Status') as HTMLInputElement).value.toLowerCase();

    const obj = {
      "PageNumber": this.currentPage,
      "RecordCount": this.pageSize,
      "ProjectType": this.ptype,
      "Client": clientFilter == "" ? null : clientFilter,
      "State": stateFilter == "" ? null : stateFilter,
      "City": cityFilter == "" ? null : cityFilter,
      "Intersection": intersectionFilter == "" ? null : intersectionFilter,
      "ProjectStatus": statusFilter == "" ? null : statusFilter,
      "DealType": dealFilter == "" ? null : dealFilter
    }



    this.dataService.getProjectDataList(obj)
      .subscribe((projects: any) => {
        this.projectList = projects.data;
        this.filteredList = projects.data;
        this.totalPages = Math.ceil(projects.TotalCount / this.pageSize);
      });
  }

  sortData(key: string): void {
    this.sortDirection[key] = !this.sortDirection[key];
    const direction = this.sortDirection[key] ? 1 : -1;
    this.filteredList.sort((a, b) => {
      return this.compareValues(a[key], b[key], direction);
    });
  }

  compareValues(a: any, b: any, direction: number): number {
    if (a == null && b == null) return 0;
    if (a == null) return -1 * direction;
    if (b == null) return 1 * direction;
    if (typeof a === 'string' && typeof b === 'string') {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }

    if (!isNaN(a) && !isNaN(b)) {
      a = Number(a);
      b = Number(b);
    }

    if (a < b) return -1 * direction;
    if (a > b) return 1 * direction;
    return 0;
  }
  ProjetcDetail(project: any) {
    localStorage.setItem("projectId", project.Id);
    this.router.navigate(['/projectdetail'])
  }
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      // this.bindProjectList(this.currentPage);
      this.bindProjectList()
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.bindProjectList()
      //  this.bindProjectList(this.currentPage);
    }
  }

  DeleteProject(project) {
    
    const currentuser = JSON.parse(localStorage.getItem('currentUser')).data.Id
    const obj = {
      "Id": project.Id,
      "UpdateBy": currentuser
    }
    let confirm = window.confirm('Are you really want to delete this record ?')
    if (confirm) {
      this.dataService.deleteProject(obj)
        .subscribe((projects: any) => {

          this.toastr.success('', projects.message);
          this.bindProjectList()
        });
    }

  }
}
