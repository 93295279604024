import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-loghtml',
  templateUrl: './loghtml.component.html',
  styleUrls: ['./loghtml.component.css']
})
export class LoghtmlComponent implements ViewCell, OnInit {
  renderValue: string;
description="";
  @Input() value: string | number;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() {
    this.description=this.rowData.Description
  }

}
