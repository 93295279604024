import { Component, OnInit, Input  } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import{CurrencyPipe  }from'@angular/common';
@Component({
  selector: 'app-site-demographic',
  templateUrl: './site-demographic.component.html',
  styleUrls: ['./site-demographic.component.css']
})
export class SiteDemographicComponent implements OnInit {
  model: any = {};
  isEdit = true;
  isUpdate = false;
  @Input() id: number;
  constructor(private dataService: DataService, private toastr: ToastrService,private currencyPipe:CurrencyPipe) {

  }

  ngOnInit() {
    this.getSiteDemographics();

  }

  getSiteDemographics() {
    if(localStorage.getItem("projectId")!="0"){
      this.id=parseInt(localStorage.getItem("projectId"))
    }
    this.dataService.getSiteDemographics({ projectId: this.id })
      .subscribe(
        result => {

          console.log(result.data);
          this.model.Id = result.data.Id
          this.model.ProjectId = this.id
          this.model.Population = result.data.Population
          this.model.TrafficCounts = result.data.TrafficCounts <= 0 ? '' : result.data.TrafficCounts
          this.model.MedianIncome = result.data.MedianIncome <= 0 ? '' : result.data.MedianIncome
          this.model.PopGrowth = result.data.PopGrowth;
          this.model.PopGrowthString = result.data.PopGrowthString;

         // this.model.StreetA = result.data.StreetA
        //  this.model.StreetB = result.data.StreetB
          this.model.MedianIncomeString = result.data.MedianIncomeString.split(".")[0]
          if (result.data.Id) {
            this.isEdit = false;
          }
          else {
            this.model.UpdateText = "Save";
            this.isEdit = false;
          }
         
         // this.model.TrafficCountsView= this.currencyPipe.transform( result.data.TrafficCounts, 'USD').replace(/[$]/g, "");
         // this.model.PopulationView= this.currencyPipe.transform( result.data.Population, 'USD').replace(/[$]/g, "");
         this.model.TrafficCountsView= this.numberWithCommas(result.data.TrafficCounts) ;
          this.model.PopulationView= this.numberWithCommas(result.data.Population) ;
          this.model.StreetA= this.numberWithCommas(result.data.StreetA) ;
          this.model.StreetB= this.numberWithCommas(result.data.StreetB) ;
        })
  }

  SaveSiteDemographics() {
    if (localStorage.getItem("projectId") != "") {
      this.model.ProjectId = localStorage.getItem("projectId")
    }
    this.model.UpdateBy = JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.dataService.saveSiteDemographics(this.model)
      .subscribe(
        result => {
          this.getSiteDemographics();
          this.toastr.success('', result.message);
          this.isEdit = true;
        })
  }

  EditSiteDemographics() {
    this.model.UpdateText = "Update";
    this.isEdit = true;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  numberdecimalOnly(event): boolean {
    // debugger;
    // if (!this.validDecimal(event.target.value)) {
    //   event.returnValue = false;
    // }
    // const charCode = (event.which) ? event.which : event.keyCode;
    // if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    // return true;
    var charCode = (event.which) ? event.which : event.keyCode
    var value = event.target.value;
    var dotcontains = value.indexOf(".") != -1;
    if (dotcontains)
        if (charCode == 46) return false;
    if (charCode == 46) return true;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }
  
  validDecimal(txt) {
    
    return txt.match(/((\d+)((\.\d{1,2})?))$/);
  }

  transformTrafficCounts(element){
  //  if(this.model.TrafficCounts!=""){
  //   var num = this.currencyPipe.transform(this.model.TrafficCounts, 'USD').replace(/[$]/g, "");
  //   element.target.value = num;
  //  }
  element.target.value = this.numberWithCommas(this.model.TrafficCounts)
  }
   transformPopulation(element){
    // if(this.model.Population!=""){
    //  var num = this.currencyPipe.transform(this.model.Population, 'USD').replace(/[$]/g, "");
    //  element.target.value = num;
    // }
    element.target.value = this.numberWithCommas(this.model.Population)
  }

  numberWithCommas(x) {
    if(x!=null){
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
  
}
   // this.model.TrafficCounts=num
   transformStreetA(element){
    element.target.value = this.numberWithCommas(this.model.StreetA)
   }
   transformStreetB(element){
    element.target.value = this.numberWithCommas(this.model.StreetB)
   }
}