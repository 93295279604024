import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  isShowLogin = true;
  constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute, private toastr: ToastrService,
    private router: Router, private permissionsService: NgxPermissionsService) { }

  ngOnInit() {
    if (localStorage.getItem('isLoggedin')) {

      this.router.navigate(['']);
    }
  }
  login() {
    if (this.model.userName == undefined || this.model.userName == "") {
      this.toastr.error('', 'Please enter your user name.');
      return
    }
    if (this.model.password == undefined || this.model.password == "") {
      this.toastr.error('', 'Please enter your password.');
      return
    }
   // this.ngProgress.start();
    this.authenticationService.login(this.model.userName, this.model.password)
      .subscribe(
        result => {
      
          if (result.messageCode == 1) {
            this.permissionsService.loadPermissions(result.userAccess);
            localStorage.setItem('isLoggedin', 'true');
            this.router.navigate(['']);
            this.toastr.success('', result.message);
           
          } else {
            this.toastr.error('', result.message);
          }

        })
  }

  forgetPassword() {
    this.isShowLogin = false;
  }
  getPassword() {
    if (this.model.userName == undefined || this.model.userName == "") {
      this.toastr.error('', 'Please enter your user name.');
      return
    }
    this.authenticationService.forgetPassword(this.model)
      .subscribe(
        result => {
          if (result.messageCode == 2) {
            this.toastr.error('', result.message);
        
          }
          else{
            this.toastr.success('', result.message);
            this.isShowLogin = true;
          }
       
        })
  }
}
