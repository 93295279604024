import { Component, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';

import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../services/dataService.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ViewlinkComponent } from '../../components/viewlink/viewlink.component';
import {AppSettings} from '../../app-Settings';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
let ApiUrl = AppSettings.API_ENDPOINT;
@Component({
  selector: 'app-documentlist',
  templateUrl: './documentlist.component.html',
  styleUrls: ['./documentlist.component.css']
})
export class DocumentlistComponent implements OnInit {
  settings: any;
  data: ServerDataSource
  constructor(private http: HttpClient,private dataService: DataService, private toastr: ToastrService,private route: Router,private confirmationDialogService: ConfirmationDialogService) { 

    this.data = new ServerDataSource(http ,{
      //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
      endPoint: ApiUrl+'Document/getDocumentFolderList',
      // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
      dataKey: 'data.data',
      totalKey: 'TotalCount',
      pagerLimitKey: 'RecordCount',
      pagerPageKey: 'PageNumber',
      filterFieldKey: '#field#',
      sortFieldKey: 'SortColumn',
      sortDirKey: 'SortOrder',
    });
   
       
    this.settings = {
      columns: {
       
        FolderName: {
          title: 'Folder Name',
          width:'20%',
        },
        
        DocumentCategory:{
          title: 'Document Category',
          width:'20%',
        },
        FirstField: {
          title: 'First Field',
          width:'10%',
        },
        SecondField: {
          title: 'Second Field',
          width:'10%',
        },
        ThirdField: {
          title: 'Third Field',
          width:'10%',

        },
        OrderNumber: {
          title: 'Order Number',
          filter: false,
          width:'8%',
        },
      },
     
      actions: {
        edit: false,
        add: false,
        delete: false,
        custom: [
          { name: 'Edit',  title: `<span  class="btn btn-info btn-rounded edit_notes"><i class="fa fa-pencil"></i> Edit</span>` },
        
         { name: 'Delete',  title: `<span  class="btn btn-danger btn-rounded"><i class="fa fa-times"></i> Delete</span>` },
        
          
         ],
        position: 'right', // left|right
       
      },
      pager: {
        display: true,
        perPage: 25
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };
  }

  ngOnInit() {
  }
  onCustom(event: any) {
    // alert(`Custom event '${event.action}' fired on row №: ${event.data.id}`)

    if (event.action == "Edit") {
      this.route.navigate(['documentdetail/'+event.data.Id])
    }
    if (event.action == "Delete") {
      let self = this;
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this document?')
      .then(function(confirmed){
        if(confirmed){
      //this.route.navigate(['documentdetail/'+event.data.Id])
       var model={Id:event.data.Id, UpdateBy:JSON.parse(localStorage.getItem('currentUser')).data.Id};
       self.dataService.deleteDocumentFolder(model)
      .subscribe(
        result => {
          if(result.messageCode==2){
            self.toastr.error('', result.message);
          }else{
          
            self.data.refresh();
            self.toastr.success('', result.message);
          }
        })

      }});
    }
  }
}
