import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  projectList:any;
  constructionList:any;
  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.bindProjectList();
  }
  bindProjectList() {

    this.dataService.getAcquisitionCriticalList()
      .subscribe(
        result => {
          this.projectList = result.data;
          console.log(this.projectList);
        })
        this.dataService.getConstructionList()
        .subscribe(
          result => {
            this.constructionList = result.data;
            console.log(this.projectList);
          })
  

  }
  onClick(item:any) {
    localStorage.setItem("projectId", item.Id);
    var previousList={};
      previousList={
        listType:"Current Projects",
        nav:"projects/1"
    }
 
    localStorage.setItem("projectType",  JSON.stringify(previousList));
    this.router.navigate(['/projectdetail'])
    
  }
}
