import { Component, OnInit, Input} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { ProjectService } from '../../services/project.service';
@Component({
  selector: 'app-entitlement-attribute',
  templateUrl: './entitlement-attribute.component.html',
  styleUrls: ['./entitlement-attribute.component.css']
})
export class EntitlementAttributeComponent implements OnInit {
   model: any = {};
   param: any = {};
  projectId: string;
  @Input() entitlementAttributeId: number;
  updateText="Update";
  isEdit=true;
  isCancel=false;
  title='Zoning';
  attBorderClass='borderGreen';
  public myDatePickerOptions: IMyDpOptions = {
    editableDateField:false,
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek:'su'
  };
  constructor(private projectService: ProjectService, private toastr: ToastrService) { 

    if(localStorage.getItem("projectId")!=""){
      this.projectId= localStorage.getItem("projectId") 
    }
  }

  ngOnInit() {
    this.getProjectAttribute();
  }

  getProjectAttribute()
  {
    this.projectService.getProjectAttribute({ projectId: this.projectId, entitlementAttributeId: this.entitlementAttributeId })
    .subscribe(
      
      result => {
        this.attBorderClass='borderGreen';
        this.model.Id= result.data.Id
        this.model.ProjectId = this.projectId 
        this.model.EntitlementAttributeId = this.entitlementAttributeId
        this.model.Required = result.data.Required
        this.model.CurrentZoning = result.data.CurrentZoning
        this.model.RequiredZonning = result.data.RequiredZonning
        this.model.AppDeadLine =result.data.AppDeadLineString!=''? {jsdate: new Date(result.data.AppDeadLineString)}:''
        this.model.DRCMTGDate = result.data.DRCMTGDateString!=''? {jsdate: new Date(result.data.DRCMTGDateString)}:'' 
        this.model.PandZ = result.data.PandZString!=''? {jsdate: new Date(result.data.PandZString)}:'' 
        this.model.CityCouncil =result.data.CityCouncilString!=''? {jsdate: new Date(result.data.CityCouncilString)}:''
        this.model.Started = result.data.StartedString!=''? {jsdate: new Date(result.data.StartedString)}:''
        this.model.Completed = result.data.CompletedString!=''? {jsdate: new Date(result.data.CompletedString)}:''
        this.model.StaffApproval= result.data.StaffApprovalString!=''? {jsdate: new Date(result.data.StaffApprovalString)} :'' 

      if(this.entitlementAttributeId==1) this.title='Zoning' 
      else if(this.entitlementAttributeId==2) this.title='Site Plan Approval' 
      else if(this.entitlementAttributeId==3) this.title='SUP/CUP' 
      else if(this.entitlementAttributeId==4) this.title='Platting' 

        if(result.data.Id)    
        {
          this.isEdit = false;
        }
        else
        {
          this.model.Required=true;
          this.updateText="Save";
          this.isEdit = false;
          this.isCancel = false;
        }
        if((this.model.Required && !this.model.Started && !this.model.Completed) || (this.model.Required && !this.model.Started && this.model.Completed))
        this.attBorderClass='borderRed';
        else if((this.model.Required && this.model.Started && !this.model.Completed))
        this.attBorderClass='borderYellow';
       })
  }

  SaveProjectAttribute()
  {
     this.param.UpdateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id; 
     this.param.Id=  this.model.Id
     this.param.ProjectId = this.projectId 
     this.param.EntitlementAttributeId = this.entitlementAttributeId
     this.param.Required = this.model.Required
     this.param.CurrentZoning = this.model.CurrentZoning
     this.param.RequiredZonning = this.model.RequiredZonning
     this.param.AppDeadLine =this.model.AppDeadLine? new Date(this.model.AppDeadLine.jsdate).toLocaleDateString():''
     this.param.DRCMTGDate =this.model.DRCMTGDate? new Date(this.model.DRCMTGDate.jsdate).toLocaleDateString():''
     this.param.PandZ = this.model.PandZ? new Date(this.model.PandZ.jsdate).toLocaleDateString():''
     this.param.CityCouncil =this.model.CityCouncil? new Date(this.model.CityCouncil.jsdate).toLocaleDateString():''
     this.param.Started = this.model.Started? new Date(this.model.Started.jsdate).toLocaleDateString():''
     this.param.Completed = this.model.Completed? new Date(this.model.Completed.jsdate).toLocaleDateString():''
     this.param.StaffApproval= this.model.StaffApproval ? new Date(this.model.StaffApproval.jsdate).toLocaleDateString():''
     this.projectService.saveProjectAttribute(this.param)
    .subscribe(
      result => {
        this.getProjectAttribute();
        this.toastr.success('', result.message);
        this.isEdit=true;
        this.isCancel = false;
      })
  }

  EditProjectAttribute()
  {
    this.updateText="Update";
    this.isEdit = true;
    this.isCancel = true;
  }

  // getFullDate(fDate)
  // {
  //   fDate.jsdate.getMonth()+'-'+ fDate.jsdate.getDate()+'-' +fDate.jsdate.getYear()
  // }

}
