import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppSettings} from '../../app-Settings';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
let ApiUrl = AppSettings.API_ENDPOINT;
@Component({
  selector: 'app-sitecontact',
  templateUrl: './sitecontact.component.html',
  styleUrls: ['./sitecontact.component.css']
})
export class SitecontactComponent implements OnInit {
  model: any = {};
  isShowForm = false;
  isShowList = true;
  isUpdate = false;
  siteContactList: any;
  contactTypeList: any;
  Id: 0;
  ContactType :any;
  buttonDisabled= false;
  settings: any;
  data: ServerDataSource

  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  constructor(private http: HttpClient,private dataService: DataService, private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService) { 
   
    this.data = new ServerDataSource(http ,{
      //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
      endPoint: ApiUrl+'Contact/getSiteContactList',
      // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
      dataKey: 'data.data',
      totalKey: 'TotalCount',
      pagerLimitKey: 'RecordCount',
      pagerPageKey: 'PageNumber',
      filterFieldKey: '#field#',
      sortFieldKey: 'SortColumn',
      sortDirKey: 'SortOrder',
    });
    this.settings = {
      columns: {
       
        Name: {
          title: 'Name',
          
      
        },
        Company: {
          title: 'Company'
        },
        Email:{
          title: 'Email'
        },
        Phone1: {
          title: 'Phone',
       
        },
        TypeName: {
          title: 'Type',
       
        },
        Cell: {
          title: 'Cell',
       
        },
      },
     
      actions: {
        edit: false,
        add: false,
        delete: false,
        custom: [
          { name: 'Edit',  title: `<span  class="btn btn-info btn-rounded edit_notes"><i class="fa fa-pencil"></i> Edit</span>` },
        
         { name: 'Delete',  title: `<span  class="btn btn-danger btn-rounded"><i class="fa fa-times"></i> Delete</span>` },
        
          
         ],
        position: 'right', // left|right
      },
      pager: {
        display: true,
        perPage: 25
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };

  }


  ngOnInit() {
    
    this.bindSiteContacte();
    this.dataService.getContactTypeWithclient({})
      .subscribe(
        result => {
          this.contactTypeList = result.data;
         // this.ContactType = 0;
        })
  }
  bindSiteContacte() {

    this.dataService.getSiteContactList({})
      .subscribe(
        result => {
          this.siteContactList = result.data;
        })


  }

  edit(data: any) {
    
    this.Id = data.Id
    this.isUpdate = true
    this.isShowForm = true;
    this.isShowList = false;
    // this.dataService.getSiteContact(this.Id)
    // .subscribe(
    //   result => {
    //     this.toastr.success('', result.message);
    this.model.Name = data.Name;
    this.model.Email = data.Email;
    this.model.Company = data.Company;
    this.model.Cell = data.Cell;
    this.model.Phone1 = data.Phone1;
    this.model.Phone2 = data.Phone2;

    this.model.Phone1Ext = data.Phone1Ext;
    this.model.Phone2Ext2 = data.Phone2Ext2;

    this.model.FAX = data.FAX;

    this.ContactType = data.ContactTypeId;
    //his.isShowList=true;
    // })

  }
  delete(data: any) {
    let self = this;
    self.Id = data.Id
    self.model.Id = this.Id;
    self.model.UpdateBy = 1;
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this contact?')
    .then(function(confirmed){
      if(confirmed){
        self.dataService.deleteSiteContact(self.model)
      .subscribe(
        result => {
          if(result.messageCode==2){
            self.toastr.error('', result.message);
          }else{
            self.bindSiteContacte();
            self.toastr.success('', result.message);
            self.isShowForm = false;
            self.isShowList = true;
          }
        })
      }
    })
  }
  add() {
    this.model.Name = "";
    this.model.Email = ""
    this.model.Company = "";
    this.model.Phone1 = "";
    this.model.Phone2 = "";
    this.model.Phone1Ext = "";
    this.model.Phone2Ext2 = "";
    this.model.FAX = "";
    this.ContactType = 0;
    this.model.Cell="";
    this.isUpdate = false;
    this.isShowForm = true;
    this.isShowList = false;
    this.model.ID="0";
    this.buttonDisabled = false;
  }
  save() {

    if (this.ContactType == undefined || this.ContactType == "") {
      this.toastr.error('', 'Please enter contact type.');
      return
    }
    if (this.model.Name == undefined || this.model.Name == "") {
      this.toastr.error('', 'Please enter name.');
      return
    }
    if (this.model.Email == undefined || this.model.Email == "") {
      this.toastr.error('', 'Please enter your email.');
      return
    }
    else {

      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.Email.trim())) {
        this.toastr.error('', 'Please enter valid email.');
        return
      }
    }
    if (this.model.Company == undefined || this.model.Company == "") {
      this.toastr.error('', 'Please enter Company.');
      return
    }
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.model.ContactType = this.ContactType;
    this.buttonDisabled = true;
    this.model.Email=this.model.Email.trim();
    this.dataService.createSiteContact(this.model)
      .subscribe(
        result => {
          this.model={};
          this.bindSiteContacte();
          this.toastr.success('', result.message);
          this.isShowForm = false;
          this.isShowList = true;
        })


  }
  update() {
    if (this.ContactType == undefined || this.ContactType == "") {
      this.toastr.error('', 'Please enter contact type.');
      return
    }
    if (this.model.Name == undefined || this.model.Name == "") {
      this.toastr.error('', 'Please enter name.');
      return
    }

    if (this.model.Email == undefined || this.model.Email == "") {
      this.toastr.error('', 'Please enter your email.');
      return
    }
    else {

      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.Email.trim())) {
        this.toastr.error('', 'Please enter valid email.');
        return
      }
    }
    if (this.model.Company == undefined || this.model.Company == "") {
      this.toastr.error('', 'Please enter Company.');
      return
    }
    this.model.Id = this.Id
    this.model.ContactType = this.ContactType;
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.model.Email=this.model.Email.trim();
    this.dataService.createSiteContact(this.model)
      .subscribe(
        result => {
          this.bindSiteContacte();
          this.toastr.success('', result.message);
          this.isShowForm = false;
          this.isShowList = true;
        })

  }
  cancel() {
    this.isShowForm = false;
    this.isShowList = true;
  }

  emailchange() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.Email)) {
      // alert(this.model.Email)
      this.dataService.checkContactEmailAvailability(this.model)
        .subscribe(
          result => {
            if (result.data == 1) {
              this.toastr.error('', 'Email already exist.');
              this.buttonDisabled = true;
            }else{
              this.buttonDisabled = false;
            }
           
           
          })
      return
    }

  }

  onCustom(event: any) {
    // alert(`Custom event '${event.action}' fired on row №: ${event.data.id}`)
    this.Id = event.data.Id
    
    if (event.action == "Edit") {
     // this.Id = data.Id
      this.isUpdate = true
      this.isShowForm = true;
      this.isShowList = false;
      // this.dataService.getSiteContact(this.Id)
      // .subscribe(
      //   result => {
      //     this.toastr.success('', result.message);
      this.model.Name = event.data.Name;
      this.model.Email = event.data.Email;
      this.model.Company = event.data.Company;
      this.model.Phone1 = event.data.Phone1;
      this.model.Phone2 =event.data.Phone2;
      this.model.Cell = event.data.Cell;
      this.model.Phone1Ext = event.data.Phone1Ext;
      this.model.Phone2Ext2 = event.data.Phone2Ext2;
  
      this.model.FAX = event.data.FAX;
  
      this.ContactType = event.data.ContactTypeId;
    }
    else if (event.action == "Delete") {
      let self = this;
      self.model.Id = this.Id;
      self.model.UpdateBy = JSON.parse(localStorage.getItem('currentUser')).data.Id;;
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this contact?')
    .then(function(confirmed){
      if(confirmed){
        self.dataService.deleteSiteContact(self.model)
      .subscribe(
        result => {
          if(result.messageCode==2){
        
            self.toastr.error('', result.message);
          }else{
        self.data.refresh();
            self.bindSiteContacte();
            self.toastr.success('', result.message);
            self.isShowForm = false;
            self.isShowList = true;
          }
        })
      }
    })
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
