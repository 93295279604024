import { Component, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../services/dataService.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LoghtmlComponent } from '../../components/loghtml/loghtml.component';
import { AppSettings } from '../../app-Settings';

let ApiUrl = AppSettings.API_ENDPOINT;

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.css']
})
export class ActivitylogComponent implements OnInit {
  settings: any;
  data: ServerDataSource

  constructor(private http: HttpClient, private dataService: DataService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) {

          this.data = new ServerDataSource(http, {
            //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
            endPoint: ApiUrl + 'SiteProfile/getActivityLog',
            // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
            dataKey: 'data.data',
            totalKey: 'TotalCount',
            pagerLimitKey: 'RecordCount',
            pagerPageKey: 'PageNumber',
            filterFieldKey: '#field#',
            sortFieldKey: 'SortColumn',
            sortDirKey: 'SortOrder',
          });

        
    this.settings = {
      columns: {
        Module: {
          title: 'Module'
        },
        ActivityType: {
          title: 'Activity Type'
        },
        Description: {
          title: 'Description',
          type: 'custom',
          // renderComponent: CustomRendererComponent,
          renderComponent: LoghtmlComponent
        },
        UserName: {
          title: 'User Name',
          
        },
        CreateDate: {
          title: 'Create Date',
          filter: false
        },
      },

      actions: {
        edit: false,
        add: false,
        delete: false,
        // custom: [
        //   { name: 'Edit', title: `<i  class="fa fa-edit"></i>` }

        // ],
        position: 'left', // left|right
      },
      pager: {
        display: true,
        perPage: 25
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };

  }

  ngOnInit() {
    this.settings.pager.display = true;
  }

}
