import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-contacttype',
  templateUrl: './contacttype.component.html',
  styleUrls: ['./contacttype.component.css']
})
export class ContacttypeComponent implements OnInit {
  model: any = {};
  isShowForm=false;
  isShowList=true;
 isUpdate=false;
 contactTypeList:any;
 Id:0;
  constructor(private dataService: DataService, private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
   
    this.bindContactType();
  }

  bindContactType(){
    // this.contactTypeList=[{
    //   Id: 1,
    //   TypeName: "Project Manager",
    //   Description:"Project Manager"
    // },
    // {
    //   Id: 2,
    //   TypeName: "Architect",
    //   Description:"Architect"
    // }]

    this.dataService.getContactType({})
    .subscribe(
      result => {
       this.contactTypeList=result.data;
      })

 
  }
  edit(data:any){
    this.Id=data.Id
    this.isUpdate=true
    this.isShowForm=true;
    this.isShowList=false;
    this.model.TypeName=data.TypeName
    this.model.Description=data.Description
  }
  delete(data:any){
    let self = this;
    self.Id=data.Id
    self.model.Id= self.Id
    self.model.UpdateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this contact type?')
    .then(function(confirmed){
      if(confirmed){
        self.dataService.deleteContactType(self.model)
    .subscribe(
      result => {
        if(result.messageCode==2){
          self.toastr.error('', result.message);
        }else{
          self.toastr.success('', result.message);

          self.bindContactType();
          self.isShowForm=false;
          self.isShowList=true;
        }
       
      })
    }
  })
  }
  add(){
    this.model.TypeName="";
    this.model.Description="";
    this.isUpdate=false;
    this.isShowForm=true;
    this.isShowList=false;
    this.model.ID= "0"
    this.model.Id= "0"
  }
  save(){

    if (this.model.TypeName == undefined || this.model.TypeName == "") {
      this.toastr.error('', 'Please enter type name.');
      return
    }
    this.model.Id= "0"
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.dataService.createContactType(this.model)
    .subscribe(
      result => {
        if(result.messageCode==2){
          this.toastr.error('', result.message);
        }else{
        this.toastr.success('', result.message);
        this.model={};
        this.bindContactType();
        this.isShowForm=false;
        this.isShowList=true;
        }
      })

 
  }
  update(){
    if (this.model.TypeName == undefined || this.model.TypeName == "") {
      this.toastr.error('', 'Please enter type name.');
      return
    }
    this.model.Id= this.Id
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.dataService.createContactType(this.model)
    .subscribe(
      result => {
        if(result.messageCode==2){
          this.toastr.error('', result.message);
        }else{
        this.bindContactType();
        this.toastr.success('', result.message);
        this.isShowForm=false;
        this.isShowList=true;
        }
      })

  }
  cancel(){
  this.isShowForm=false;
    this.isShowList=true;
  }
}
