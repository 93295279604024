import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard } from './auth/auth.guard';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ProjectlistComponent } from './components/projectlist/projectlist.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/dataService.service';
import { ProjectService } from './services/project.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { UsersComponent } from './components/users/users.component';
import { ProjectdetailComponent } from './components/projectdetail/projectdetail.component';
import { ProjectdataComponent } from './components/projectdata/projectdata.component';
import { SitedataComponent } from './components/sitedata/sitedata.component';
import { NotesComponent } from './components/notes/notes.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PsaleaseComponent } from './components/psalease/psalease.component';
import { ContacttypeComponent } from './components/contacttype/contacttype.component';
import { SitecontactComponent } from './components/sitecontact/sitecontact.component';
import { MyDatePickerModule } from 'mydatepicker';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SitephotoComponent } from './components/sitephoto/sitephoto.component';
import { ViewlinkComponent } from './components/viewlink/viewlink.component';
import { SiteDemographicComponent } from './components/site-demographic/site-demographic.component';
import { DocumentlistComponent } from './components/documentlist/documentlist.component';
import { DocumentdetailComponent } from './components/documentdetail/documentdetail.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { EntitlementComponent } from './components/entitlement/entitlement.component';
import { ConstructionComponent } from './components/construction/construction.component';
import { CloseoutComponent } from './components/closeout/closeout.component';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ProjectteamComponent } from './components/projectteam/projectteam.component';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap';
import { ActivitylogComponent } from './components/activitylog/activitylog.component';
import { EntitlementAttributeComponent } from './components/entitlement-attribute/entitlement-attribute.component';
import { ContracttermComponent } from './components/contractterm/contractterm.component';
import { ThirdPartyApprovalsComponent } from './components/third-party-approvals/third-party-approvals.component';
import { ProjectMiscellaneousComponent } from './components/project-miscellaneous/project-miscellaneous.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';
import { NgxEditorModule } from 'ngx-editor';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ImageModal } from '../directives/angular2-image-popup/image-modal-popup';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";
import { CurrencyPipe } from '@angular/common';
import { LoghtmlComponent } from './components/loghtml/loghtml.component';

export function createTranslateLoader(http: HttpClient) {
  // for development
  // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProjectlistComponent,
    LoginComponent,
    UsersComponent,
    ProjectdetailComponent,
    ProjectdataComponent,
    SitedataComponent,
    NotesComponent,
    PsaleaseComponent,
    ContacttypeComponent,
    SitecontactComponent,
    SitephotoComponent,
    ViewlinkComponent,
    SiteDemographicComponent,
    DocumentlistComponent,
    DocumentdetailComponent,
    TenantComponent,
    EntitlementComponent,
    ConstructionComponent,
    CloseoutComponent,
    DocumentsListComponent,
    ContactsComponent,
    ActivitylogComponent,
    EntitlementAttributeComponent,
    ContracttermComponent,
    ThirdPartyApprovalsComponent,
    ProjectMiscellaneousComponent,
    ProfileComponent,
    ProjectteamComponent,
    ConfirmationDialogComponent,
    ImageModal,
    LoghtmlComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    Ng2SmartTableModule,
    MyDatePickerModule,
    ModalModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgProgressModule,
    NgbModule.forRoot(),
    NgxEditorModule,
    TextMaskModule,
    CurrencyMaskModule


  ],
  providers: [CurrencyPipe, AuthGuard, AuthenticationService, DataService, BsModalService, ProjectService, ConfirmationDialogService, { provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true }, { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }],
  bootstrap: [AppComponent],
  entryComponents: [ViewlinkComponent, ConfirmationDialogComponent, LoghtmlComponent],

})
export class AppModule { }
