import { Component, OnInit, Input, ElementRef, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { IMyDpOptions } from 'mydatepicker';
import {ModalModule } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.css']
})
export class DocumentsListComponent implements OnInit {
  model: any = {};
  @Input() id: number;
  @Input() DocumentCategory: number;
  editRowId: any;
  documentsList: any
  modalRef: BsModalRef;
  folderName:"";
  folderId:"";
  ProjectID:""
  CategoryID:""
  documentFileList:any
  formData = new FormData();
  uploadButtonDisable=true;
  extensionLists: any = {};
  
isValidFileType(fName, fType) {
  return this.extensionLists[fType].indexOf(fName.split('.').pop()) > -1;
}
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
  };
  constructor(private dataService: DataService, private toastr: ToastrService,private el: ElementRef,private modalService: BsModalService,private http:HttpClient) { }

  ngOnInit() {
    this.extensionLists.image = ['exe'];
    if (this.id != 0) {
      this.bindDocument();
    }
  }
  bindDocument() {
    this.dataService.getDocumentList({ ProjectID: this.id, DocumentCategory: this.DocumentCategory })
      .subscribe(
        result => {
          this.documentsList = result.data;

        })

  }
  edit(id: any) {
    this.editRowId = id;
  }
  update(data: any) {
    this.editRowId = "";
    this.model.ProjectID = this.id;
    this.model.Id =  data.Id;
    this.model.FolderID = data.FolderID;
    this.model.DocumentCategory = data.DocumentCategory;
    this.model.FirstFieldID = data.FirstFieldID;

    if (data.FirstFieldType == "1") {
      this.model.FirstColumnValue = data.FirstColumnValue;
    }
    else {
      this.model.FirstColumnValue = data.FirstColumnValue;
    }
    // if (data.SecondFieldType == "1") {
    //   this.model.SecoundColumnValue = data.SecoundColumnValue;
    // } else {
    //   this.model.SecondColumnValue = data.SecoundColumnValue;
    // }
    this.model.SecoundColumnValue = data.SecoundColumnValue;
    this.model.SecondFieldID = data.SecondFieldID;
    this.model.ThirdFieldID = data.ThirdFieldID;
    if (data.ThirdFieldType == "1") {
      this.model.ThirdColumnValue = data.ThirdColumnValue;
    } else {
      this.model.ThirdColumnValue = data.ThirdColumnValue;
    }


    // if (data.FirstFieldType == "1" && data.SecondFieldType == "1") {
      
    //   if(data.SecoundColumnValue!="" && data.FirstColumnValue!="")
    //   {
    //     var date2 = new Date(data.SecoundColumnValue);
    //     var date1 = new Date(data.FirstColumnValue);
    //     var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    //     this.model.Duration =Math.ceil(timeDiff / (1000 * 3600 * 24));
       
    //   }else{
    //     this.model.Duration = 0
    //   }
    // }else{
    //   this.model.Duration = 0
    // }

    this.model.Color = data.Color;
    this.model.Duration = data.Duration;
    this.model.UpdateBy = JSON.parse(localStorage.getItem('currentUser')).data.Id;
    console.log(this.model)
    this.dataService.updateDocumentList(this.model)
      .subscribe(
        result => {
          this.toastr.success('', result.message);
          this.bindDocument();
        
        })
  }
  readUrl(event: any) {
    this.formData = new FormData
    if (this.isValidFileType(event.target.files[0].name, "image") == true) {
      event.srcElement.value = null;
      this.toastr.error('', "Please select image,pdf,doc file.");
      return
    }
    if(event.target.files[0].size >  600000000)  // validation according to file size
    { 
         (document.getElementById("imageUpload") as HTMLInputElement).value = "";
         event.srcElement.value = null;
       this.toastr.error('', "Please size can't be more then 500 mb.");
    return
    }
    this.uploadButtonDisable=false;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => { }
      let files = event.target.files;
      this.formData.append("Project_Image", files[0]);
      reader.readAsDataURL(event.target.files[0]);
      
    }
  }
  upload(){
    if(localStorage.getItem("projectId")!=""){
      this.id= parseInt(localStorage.getItem("projectId"))
    }
    this.formData.append("Id",  "0");
    this.formData.append("FolderID",  this.folderId);
    this.formData.append("ProjectID",  this.id.toString());
    this.formData.append("CategoryID",this.DocumentCategory.toString() );
    this.formData.append("UpdateBy",  JSON.parse(localStorage.getItem('currentUser')).data.Id.toString());
    this.dataService.saveDocumentFile(this.formData)
    .subscribe(
      result => {
        this.formData = new FormData
        this.toastr.success('', result.message);
        (document.getElementById("imageUpload") as HTMLInputElement).value = "";
        this.bindDocumentFileList();
        this.bindDocument();
        this.uploadButtonDisable=true;
      }) 
  }

  bindDocumentFileList(){
    this.dataService.getDocumentFileList({ CategoryID: this.DocumentCategory,FolderID:this.folderId, ProjectID:  this.id.toString()})
    .subscribe(
      result => {
        this.documentFileList=result.data;   
      })
  }

  delete(item: any) {
    var r = window.confirm("Are you sure, you want to delete this file?");
    if (r == true) {
    let updateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id; 
    this.dataService.deleteDocumentFile({ Id: item.Id , UpdateBy:updateBy})
    .subscribe(
      result => {
        this.formData = new FormData
        this.toastr.success('', result.message);
        this.bindDocumentFileList();
        this.bindDocument();
      })
    }
  }
  downlaod(item: any){
    this.getImage(item.SitePhotoVirtualUrl).subscribe(
      (res) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(res);
            a.download = item.Project_Image;     
            document.body.appendChild(a);

            a.click();
      });
    }
  
  openModal(template: TemplateRef<any>, item: any) {

   // this.postId = item._id
  
   this.folderName=item.FolderName;
   this.folderId=item.FolderID;
   this.ProjectID=item.ProjectID;
   this.CategoryID=item.DocumentCategory;
   this.bindDocumentFileList();
    this.modalRef = this.modalService.show(template);

  }
  closeFirstModal() {
    this.modalRef.hide();
    this.modalRef = null;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getImage(imageUrl: string) {
    return this.http.get(imageUrl, {observe: 'response', responseType: 'blob'})
      .map((res) => {
        return new Blob([res.body], {type: res.headers.get('Content-Type')});
      })
  }
}
