import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
import { parse } from 'querystring'
import { LocationStrategy, Location } from '@angular/common';
@Component({
  selector: 'app-closeout',
  templateUrl: './closeout.component.html',
  styleUrls: ['./closeout.component.css']
})
export class CloseoutComponent implements OnInit {

  id = 0;
  client = ""
  apiReturned = true;
  title = "Close-Out Notes";
  backTitle = "";
  nav = "";
  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private _location: Location, private toastr: ToastrService) {
    if (localStorage.getItem("projectId") == "0") {
      this._location.back();
    }
    if (JSON.parse(localStorage.getItem('projectType')) != null) {
      this.backTitle = JSON.parse(localStorage.getItem('projectType')).listType;
      this.nav = JSON.parse(localStorage.getItem('projectType')).nav;
    }
    this.id = parseInt(localStorage.getItem("projectId"));
    this.dataService.getProjectData({ Id: this.id })
      .subscribe(
        result => {
          this.client = result.data.ClientName + " / " + result.data.Intersection + " / " + result.data.City;
        })
  }

  ngOnInit() {
  }
  back() {
    this.router.navigate([this.nav])
  }
}
