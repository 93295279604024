import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map'
import { map } from 'rxjs/operators';
import { AppSettings } from '../app-Settings';
let ApiUrl = AppSettings.API_ENDPOINT;

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

constructor(private http: HttpClient, private route: ActivatedRoute,private router: Router) { }

  getProjectAttribute(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/getProjectAttribute', params)
      .map(data => {
        return data;
      });
  }

  saveProjectAttribute(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/saveProjectAttribute', params)
      .map(data => {
        return data;
      });
  }

  getProjectContractTerms(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/getProjectContractTerms', params)
      .map(data => {
        return data;
      });
  }

  saveProjectContractTerms(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/saveProjectContractTerms', params)
      .map(data => {
        return data;
      });
  }

  getThirdPartyApproval(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/getThirdPartyApproval', params)
      .map(data => {
        return data;
      });
  }

  saveThirdPartyApproval(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/saveThirdPartyApproval', params)
      .map(data => {
        return data;
      });
  }

  getProjectMiscellaneous(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/getProjectMiscellaneous', params)
      .map(data => {
        return data;
      });
  }

  saveProjectMiscellaneous(params) {
    return this.http.post<any>(ApiUrl + 'Entitlement/saveProjectMiscellaneous', params)
      .map(data => {
        return data;
      });
  }

  
}
