import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../services/project.service';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-contractterm',
  templateUrl: './contractterm.component.html',
  styleUrls: ['./contractterm.component.css']
})
export class ContracttermComponent implements OnInit {
  model: any = {
    InsPeriodDays:0,ApprovalPeriodDays:0,ClosingPeriodDays:0,
    InsExt1Avl:0,  InsExt2Avl:0,  InsExt3Avl:0,  InsExt1Used:0, InsExt2Used:0, InsExt3Used:0,
    APDExt1Avl:0,APDExt2Avl:0,APDExt3Avl:0,APDExt1Used:0,APDExt2Used:0,APDExt3Used:0,
    CPDExt1Avl:0,CPDExt1Used:0
    

  };
  isShowEdit = true;
  @Input() id: number;
  termId=0
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek:'su'
  };
  constructor(private projectService: ProjectService, private toastr: ToastrService) { }

  ngOnInit() {
   this.getTerms();
  }
  edit() {
    this.isShowEdit = false;
  }
  update() {
    this.model.Id = this.termId;
    this.model.ProjectId = this.id;
  
    //var dateForamate =  this.model.ExecutionDate1.date.month + "-"  + this.model.ExecutionDate1.date.day + "-" +  this.model.ExecutionDate1.date.year;
    if(this.model.ExecutionDate1!=undefined){
      this.model.ExecutionDate =new Date(this.model.ExecutionDate1.jsdate).toLocaleDateString()// dateForamate;
    }else{
      this.model.ExecutionDate="";
    }
   
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.projectService.saveProjectContractTerms(this.model)
      .subscribe(
        result => {
          this.toastr.success('', result.message);
          this.isShowEdit = true;
          this.getTerms();
        })

  }
  getTerms(){
    this.projectService.getProjectContractTerms({ ProjectId: this.id })
    .subscribe(
      result => {
        this.termId = result.data.Id;
        this.model.InsPeriodDays= result.data.InsPeriodDays;
        this.model.ApprovalPeriodDays= result.data.ApprovalPeriodDays;
        this.model.ClosingPeriodDays= result.data.ClosingPeriodDays;
        this.model.InsExt1Avl= result.data.InsExt1Avl;
        this.model.InsExt2Avl= result.data.InsExt2Avl;
        this.model.InsExt3Avl= result.data.InsExt3Avl;

        this.model.InsExt1Used= result.data.InsExt1Used;
        this.model.InsExt2Used= result.data.InsExt2Used;
        this.model.InsExt3Used= result.data.InsExt3Used;
      
        this.model.APDExt1Avl= result.data.APDExt1Avl;
        this.model.APDExt2Avl= result.data.APDExt2Avl;
        this.model.APDExt3Avl= result.data.APDExt3Avl;
        this.model.APDExt1Used= result.data.APDExt1Used;
        this.model.APDExt2Used= result.data.APDExt2Used;
        this.model.APDExt3Used= result.data.APDExt3Used;

        this.model.InspectionPeriod= result.data.InspectionPeriod;
        this.model.ApprovalPeriod= result.data.ApprovalPeriod;
        this.model.closePeriod= result.data.closePeriod;


        this.model.InspectionPeriodUrgency= result.data.InspectionPeriodUrgency;
        this.model.ApprovalPeriodUrgency= result.data.ApprovalPeriodUrgency;
        this.model.CloseUrgency= result.data.CloseUrgency;


        this.model.ExecutionDateString= result.data.ExecutionDateString;


        if(result.data.ExecutionDate!="0001-01-01T00:00:00"){
          this.model.ExecutionDate1 ={jsdate: new Date(result.data.ExecutionDateString)}

        }
        

        // if (result.data.ExecutionDate != "" && result.data.ExecutionDate !="0001-01-01T00:00:00") {
        //   var datePart = result.data.ExecutionDateString
        //   var day = datePart.split("/")[1];
        //   var month = datePart.split("/")[0];
        //   var year = datePart.split("/")[2];
      
        //   this.model.ExecutionDate1 ={ date: { year: year, month: month, day: day } };
        //   //this.model.viewREC_Submitted=month+"/"+day+"/"+year;
        // }

   
      });
  }
  ConvertToInt(val){
    return parseInt(val);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
