import { Component, OnInit, Input} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { ProjectService } from '../../services/project.service';
@Component({
  selector: 'app-third-party-approvals',
  templateUrl: './third-party-approvals.component.html',
  styleUrls: ['./third-party-approvals.component.css']
})
export class ThirdPartyApprovalsComponent implements OnInit {
 pmodel: any;
 projectId: string;
 updateText="Update";
 isEdit=false;
 isCancel=false;
 isAdd=true;
 isAdded=false;
 tPABorderClass='borderRed';
 public myDatePickerOptions: IMyDpOptions = {
  editableDateField:false,
   dateFormat: 'mm/dd/yyyy',
   firstDayOfWeek:'su'
 };
 constructor(private projectService: ProjectService, private toastr: ToastrService) { 

   if(localStorage.getItem("projectId")!=""){
     this.projectId= localStorage.getItem("projectId") 
   }
 }

 ngOnInit() {
   this.getThirdPartyApproval();
 }
 addNewRow()
 {
  this.pmodel.push({
    ApprovedDate: "",
    ApprovedDateString: "",
    Id: 0,
    LabelText: "",
    LabelValue: "",
    ProjectId:  this.projectId,
    SubmittedDate: "",
    SubmittedDateString: ""
});
this.isAdd=false;
this.isAdded=true;
this.isEdit = true;
 }
 getThirdPartyApproval()
 {
   this.projectService.getThirdPartyApproval({ projectId: this.projectId})
   .subscribe(
     
     result => {
      this.tPABorderClass='borderRed';  
       this.pmodel= result.data
       var submittedDateCount=0, approvedDateCount=0, totalCount=result.data.length;
          this.pmodel.forEach(item => {
            item.ApprovedDate =item.ApprovedDateString!=''? {jsdate: new Date(item.ApprovedDateString)}:'';
            item.SubmittedDate =item.SubmittedDateString!=''? {jsdate: new Date(item.SubmittedDateString)}:'';
             if(item.SubmittedDate)
             submittedDateCount+=1;
             if(item.ApprovedDate)
            approvedDateCount+=1;
        });
        if(submittedDateCount==totalCount)
        this.tPABorderClass='borderYellow';
         if(approvedDateCount==totalCount || (submittedDateCount==totalCount && approvedDateCount==totalCount))
        this.tPABorderClass='borderGreen';
         if(approvedDateCount!=totalCount && submittedDateCount!=totalCount)
        this.tPABorderClass='borderRed';

       if(result.data[0].Id)    
       {
         this.isEdit = false;
       }
       else
       {
         this.updateText="Save";
         this.isEdit = false;
         this.isCancel = false;
       }
      })
 }

 saveThirdPartyApproval()
 {
    let updateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id; 
   
    let thirdPartyItems = [];
    this.pmodel.forEach(item => {

      let thirdPartyItem ={
        Id: item.Id,
        UpdateBy:updateBy,
        LabelText:item.LabelText,
        LabelValue: item.LabelValue,
        ProjectId:  this.projectId,
        ApprovedDate:  item.ApprovedDate ? new Date(item.ApprovedDate.jsdate).toLocaleDateString():'',
        SubmittedDate:  item.SubmittedDate?  new Date(item.SubmittedDate.jsdate).toLocaleDateString():''

      }
      thirdPartyItems.push(thirdPartyItem);
  });
    
    this.projectService.saveThirdPartyApproval(thirdPartyItems)
   .subscribe(
     result => {
       this.getThirdPartyApproval();
       this.toastr.success('', result.message);
       this.isEdit=true;
       this.isCancel = false;
       this.isAdd=true;
       this.isAdded=false;
     })
 }

 editThirdPartyApproval()
 {
   this.updateText="Update";
   this.isEdit = true;
   this.isCancel = true;
   this.isAdd=false;
 }

}
