import { Component, Directive, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
//import * as $ from 'jquery';
//import 'magnific-popup';
import { ImageModal } from 'node_modules/angular2-image-popup/directives/angular2-image-popup/image-modal-popup';

@Component({
  selector: 'app-sitephoto',
  templateUrl: './sitephoto.component.html',
  styleUrls: ['./sitephoto.component.css']
})



export class SitephotoComponent implements OnInit {
  //@ViewChild('img') imgElement: ElementRef;
  formData = new FormData();
  photoList: any;
  isUpload = false;
  extensionLists: any = {};
  @Input() id: number;
  IsphotoTab: boolean = true;
  IsdocTab: boolean = false;
  openModalWindow: boolean = false;
  imagePointer: number;
  images = []
  document = [];
  IsPhotoUpload: boolean = true
  IsDocUpload: boolean = true;
  DocOrder: boolean = true;
  isValidFileType(fName, fType) {
    return this.extensionLists[fType].indexOf(fName.split('.').pop()) > -1;
  }

  constructor(private dataService: DataService, private toastr: ToastrService, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {

    this.extensionLists.image = ['jpg', 'gif', 'bmp', 'png', 'jpeg', "PNG", "JPEG", "JPG"];
    this.extensionLists.document = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "txt",
    ]

    this.bindSitePhoto();
    this.bindSiteDocument();
  }

  OpenImageModel(imageSrc, images) {
    //alert('OpenImages');
    var imageModalPointer;
    for (var i = 0; i < images.length; i++) {
      if (imageSrc === images[i].img) {
        imageModalPointer = i;
        console.log('jhhl', i);
        break;
      }
    }
    this.openModalWindow = true;
    this.images = images;
    this.imagePointer = imageModalPointer;
  }
  cancelImageModel() {
    this.openModalWindow = false;
  }
  // ngAfterViewInit(): void {
  //   console.log(this.imgElement)
  //   $(this.imgElement.nativeElement).magnificPopup({ type: 'image' });
  // }

  bindSitePhoto() {
    this.dataService.getSitePhoto({ ProjectID: this.id })
      .subscribe(
        result => {

          this.images = [];
          this.photoList = result.data;
          result.data.forEach(element => {
            this.images.push({ thumb: element.SitePhotoVirtualUrl, img: element.SitePhotoVirtualUrl, description: element.Project_Image, Id: element.Id, Title: element.Title })
          });
          // [
          //   { thumb: 'assets/images/gallery/thumbs/chair.jpg', img: 'assets/images/gallery/chair.jpg', description: 'Image 1' },
          //   { thumb: 'assets/images/gallery/thumbs/chair2.jpg', img: 'assets/images/gallery/chair2.jpg', description: 'Image 2' },
          //   { thumb: 'assets/images/gallery/thumbs/chair3.jpg', img: 'assets/images/gallery/chair3.jpg', description: 'Image 3' },
          //   { thumb: 'assets/images/gallery/thumbs/chair4.jpg', img: 'assets/images/gallery/chair4.jpg', description: 'Image 4' },

          // ];
        })
  }


  bindSiteDocument() {

    this.dataService.getSiteDocument({ ProjectID: this.id, SearchText: '', Asc: this.DocOrder })
      .subscribe(
        result => {

          this.document = [];

          this.photoList = result.data;
          result.data.forEach(element => {

            this.document.push({ url: element.SiteDocVirtualUrl, name: element.Project_Image, Id: element.Id, Title: element.Title })
          });
        })
  }



  upload() {
    const phototitle = document.getElementById('photo-title')! as HTMLInputElement;
    if (phototitle.value.length <= 0) {
      this.toastr.error('', "Please enter title.");
      (document.getElementById("imageUpload") as HTMLInputElement).value = "";
      this.isUpload = false;
      return;
    }
    if (!this.isUpload) {
      this.toastr.error('', "Please choose a photo");
      return;
    }

    if (localStorage.getItem("projectId") != "") {
      this.id = parseInt(localStorage.getItem("projectId"))
    }
    let label = document.querySelector('.custom-file-label') as HTMLLabelElement
    const title = document.getElementById('photo-title') as HTMLInputElement;
    this.formData.append("Id", "0");
    this.formData.append("ProjectID", this.id.toString());
    this.formData.append("Title", title.value);
    this.formData.append("UpdateBy", JSON.parse(localStorage.getItem('currentUser')).data.Id);
    title.value = ''
    label.innerHTML = ''
    this.dataService.saveSitePhoto(this.formData)
      .subscribe(
        result => {

          this.formData = new FormData
          if (result.messageCode === 2) {
            this.toastr.error('', result.message);
          }
          else {
            this.toastr.success('', result.message);
          }
          (document.getElementById("imageUpload") as HTMLInputElement).value = "";
          this.isUpload = false;
          this.bindSitePhoto();
        })
  }
  uploadDocument() {
    const doctitle = document.getElementById('doc-title')! as HTMLInputElement;
    if (doctitle.value.length <= 0) {
      this.toastr.error('', "Please enter title.");
      (document.getElementById("imageUpload") as HTMLInputElement).value = "";
      this.isUpload = false;
      return;
    }
    if (!this.isUpload) {
      this.toastr.error('', "Please choose a Document");
      return;
    }

    if (localStorage.getItem("projectId") != "") {
      this.id = parseInt(localStorage.getItem("projectId"))
    }
    const title = document.getElementById('doc-title') as HTMLInputElement;
    let label = document.querySelector('.custom-file-label') as HTMLLabelElement
    this.formData.append("Id", "0");
    this.formData.append("Title", title.value);
    this.formData.append("ProjectID", this.id.toString());
    this.formData.append("UpdateBy", JSON.parse(localStorage.getItem('currentUser')).data.Id);
    title.value = ''
    label.innerHTML = ''
    this.dataService.saveSiteDocument(this.formData)
      .subscribe(
        result => {
          this.formData = new FormData
          if (result.messageCode === 2) {
            this.toastr.error('', result.message);
          }
          else {
            this.toastr.success('', result.message);
          }
          (document.getElementById("imageUpload") as HTMLInputElement).value = "";
          this.isUpload = false;

          this.bindSiteDocument();
        })
  }





  readUrl(event: any) {

    if (this.IsphotoTab) {
      this.formData = new FormData
      let label = document.querySelector('.custom-file-label') as HTMLLabelElement
      if (this.isValidFileType(event.target.files[0].name, "image") == false) {
        event.srcElement.value = null;
        this.toastr.error('', "Please select only image.");
        return
      }
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
        }
        let files = event.target.files;
        this.formData.append("Project_Image", files[0]);
        reader.readAsDataURL(event.target.files[0]);
        label.classList.add('selected')
        label.innerHTML = files[0].name
        this.isUpload = true;
      }//
    }
    else {
      this.formData = new FormData
      let label = document.querySelector('.custom-file-label') as HTMLLabelElement
      if (this.isValidFileType(event.target.files[0].name, "document") == false) {
        event.srcElement.value = null;
        this.toastr.error('', "Please select only document type files.");
        return
      }
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
        }
        let files = event.target.files;
        this.formData.append("Project_Document", files[0]);
        reader.readAsDataURL(event.target.files[0]);
        label.classList.add('selected')
        label.innerHTML = files[0].name
        this.isUpload = true;
      }
    }
  }

  delete(item: any) {

    let self = this;
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this?')
      .then(function (confirmed) {
        if (confirmed) {

          item.UpdateBy = JSON.parse(localStorage.getItem('currentUser')).data.Id
          self.dataService.deletePhoto(item)
            .subscribe(
              result => {

                self.formData = new FormData
                self.toastr.success('', result.message);
                self.bindSitePhoto();
                self.bindSiteDocument();
              })
        }
      });
  }

  ChangephotoTab() {
    if (!this.IsphotoTab) {
      this.IsdocTab = false;
      this.IsphotoTab = true;
    }
  }
  ChangedocTab() {
    if (!this.IsdocTab) {
      this.IsphotoTab = false;
      this.IsdocTab = true;

    }
    this.IsPhotoUpload = true;
    this.IsDocUpload = true;
  }
  photoListPage() {
    this.IsPhotoUpload = this.IsPhotoUpload ? false : true;
    this.IsDocUpload = this.IsDocUpload ? false : true;
    this.bindSitePhoto();
    this.bindSiteDocument();
  }


  Validate50Char() {
    const doctitle = document.getElementById('doc-title')! as HTMLInputElement;
    const phototitle = document.getElementById('photo-title')! as HTMLInputElement;
    if (doctitle != null) {
      if (doctitle.value.length > 50) {
        this.toastr.error('', "Please enter 50 characters only in title.");
        doctitle.value = doctitle.value.substring(0, 50);
      }
    }
    else if (!phototitle != null) {
      if (phototitle.value.length > 50) {
        this.toastr.error('', "Please enter 50 characters only in title.");
        phototitle.value = phototitle.value.substring(0, 50);
      }
    }
  }

  SearchByTitle() {
    let arrow = document.getElementById('arrow');
    if (arrow != null) {
      if (this.DocOrder) {
        arrow.classList.remove('fa-arrow-up')
        arrow.classList.add('fa-arrow-down')
        this.DocOrder = false
      }
      else {
        arrow.classList.remove('fa-arrow-down')
        arrow.classList.add('fa-arrow-up')
        this.DocOrder = true
      }
    }
    let searchtext = document.getElementById('txtsearch') as HTMLInputElement;
    this.dataService.getSiteDocument({ ProjectID: this.id, SearchText: searchtext.value, Asc: this.DocOrder })
      .subscribe(
        result => {
          this.document = [];

          this.photoList = result.data;
          result.data.forEach(element => {

            this.document.push({ url: element.SiteDocVirtualUrl, name: element.Project_Image, Id: element.Id, Title: element.Title })
          });
        })
  }

}
