import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { Tree } from '@angular/router/src/utils/tree';
import { LocationStrategy } from '@angular/common';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean
  isDetail: boolean
  isMainMenu: boolean
  showNav: boolean
  name = "";
  email = "";
  crl="";
  constructor(private router: Router, private route: ActivatedRoute, private url: LocationStrategy, authenticationService:AuthenticationService) { 

    authenticationService.getLoggedInName.subscribe(name => this.changeName(name));
  }
  private changeName(data: any): void {
    this.name = data.FirstName;
    this.email= data.Email
}
  ngOnInit() {
    if ( localStorage.getItem('currentUser') !=null && JSON.parse(localStorage.getItem('currentUser')).data != null) {
      this.name = JSON.parse(localStorage.getItem('currentUser')).data.FirstName;
      this.email = JSON.parse(localStorage.getItem('currentUser')).data.Email;
      this.isShowMenu();
    }
   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.crl=event.url.split('/')[1];
      }
    });
  }
  isValid(): boolean {
    if (this.router.url == "/" || this.router.url == "/projects/1" || this.router.url == "/completeprojects/2" || this.router.url == "/deadprojects/3" || this.router.url == "/users" || this.router.url == "/sitecontact" || this.router.url == "/contacttype" || this.router.url == "/documentlist" || this.router.url == "/activitylog"|| this.crl=="documentdetail" ) {
      this.isDetail = false
      this.isMainMenu = true;

    } else {
      this.isDetail = true
      this.isMainMenu = false;
    }


    if (this.router.url == "/login") {

      return false
    }
    this.isShowMenu();

    return true
  }


  logout() {
    localStorage.setItem('isLoggedin', 'false');
    localStorage.clear();
    this.router.navigate(['login']);
  }
  isShowMenu() {
    
    if (this.isMainMenu == true && JSON.parse(localStorage.getItem('currentUser')).data.MemberType == "Administrator") {
      this.showNav = true;
    } else {
      this.showNav = false;
    }
  }
}
