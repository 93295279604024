import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/dataService.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-documentdetail',
  templateUrl: './documentdetail.component.html',
  styleUrls: ['./documentdetail.component.css']
})
export class DocumentdetailComponent implements OnInit {
  model: any = {};
  documentCategoryList: any;
  documentRequiredFieldList: any;
  documentRequiredFieldList1: any;
  documentRequiredFieldList2: any;
  documentRequiredFieldList3: any;
  contactTypeList: any;
  Id = 0;
  columnName1 = 'Order Date';
  selectelm = { FieldColumn: 0, FieldName: "Select", Id: 0 };
  isDuplicate = false;
  IsAlert2=false;
  isDate=0;
  constructor(private dataService: DataService, private toastr: ToastrService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.Id = params['id']
      }
    })
  }



  ngOnInit() {
    this.dataService.getDocumentCategory({})
      .subscribe(
        result => {
          this.documentCategoryList = result.data
          //this.documentCategoryList.unshift(this.selectelm);
        })

    this.dataService.getContactTypeWithoutclient({})
      .subscribe(
        result => {
          this.contactTypeList = result.data;

        })
    this.dataService.getDocumentRequiredField({})
      .subscribe(
        result => {
          this.documentRequiredFieldList = result.data
          this.documentRequiredFieldList1 = this.documentRequiredFieldList.filter(field => field.FieldColumn === 1);
          this.documentRequiredFieldList1.unshift(this.selectelm);


          this.documentRequiredFieldList2 = this.documentRequiredFieldList.filter(field => field.FieldColumn === 2);
          this.documentRequiredFieldList2.unshift(this.selectelm);


          this.documentRequiredFieldList3 = this.documentRequiredFieldList.filter(field => field.FieldColumn === 3);
          this.documentRequiredFieldList3.unshift(this.selectelm);
          this.getDocFolder();


        })
  }

  getDocFolder() {
    this.dataService.getDocumentFolder({ id: this.Id })
      .subscribe(
        result => {
          this.model = result.data;

          this.model.DocumentCategory = parseInt(result.data.DocumentCategory)

          this.model.FirstField = this.documentRequiredFieldList.filter(field => field.Id === parseInt(result.data.FirstField))[0];
          this.model.SecondField = parseInt(result.data.SecondField)
          this.model.ThirdField = parseInt(result.data.ThirdField)

          if (!this.model.DocumentCategory) this.model.DocumentCategory = 0;
          if (!this.model.FirstField) this.model.FirstField = this.selectelm;
          if (!this.model.SecondField) this.model.SecondField = 0;
          if (!this.model.ThirdField) this.model.ThirdField = 0;

          let filteredColumn = this.documentRequiredFieldList.filter(field => field.Id === this.model.FirstField.Id && field.FieldColumn === 1)[0];
          if (filteredColumn)
            this.columnName1 = filteredColumn.FieldName;
            if(result.data.Alert1!=undefined && result.data.Alert1!=0){
              this.model.IsAlertActive=true;
            }
            if(result.data.Alert2!=undefined && result.data.Alert2!=0){
              this.IsAlert2=true;
            }
        })
       
  }

  saveDocFolder(btnType) {
    if (this.model.DocumentCategory <= 0) {
      this.toastr.error('', 'Please select document category.');
      return
    }
    if (!this.model.FolderName) {
      this.toastr.error('', 'Please enter document type name.');
      return
    }
    if ((this.model.SecondField > 0 || this.model.ThirdField > 0) && this.model.FirstField.Id <= 0) {
      this.toastr.error('', 'Please select column 1.');
      return
    }
    if(btnType==2){
      if(this.model.Alert2!=0){
        this.IsAlert2=true;
      }
    }
    if (btnType == 3) {
      if (!this.model.FromName) {
        this.toastr.error('', 'Please enter name.');
        return
      }
      if (!this.model.EmailSubject) {
        this.toastr.error('', 'Please enter subject.');
        return
      }
      if (!this.model.FromEmail) {
        this.toastr.error('', 'Please enter From Email.');
        return
      }
      else{
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.FromEmail)) {
          this.toastr.error('', 'Please enter valid email.');
          return
        }
      }
      if (this.model.ToContactType1 <= 0) {
        this.toastr.error('', 'Please select contact type 1.');
        return
      }
      if (!this.model.BodyText) {
        this.toastr.error('', 'Please enter body text (Alert1)');
        return
      }
      if(this.IsAlert2=true){
        if (!this.model.Alert2BodyText) {
          this.toastr.error('', 'Please enter body text (Alert2)');
          return
        }
      }
      
    }
    this.model.UpdateBy = JSON.parse(localStorage.getItem('currentUser')).data.Id;
    var obj=this.model.FirstField;
    this.model.FirstField= obj.Id
//console.log(this.model)
    this.dataService.saveDocumentFolder(this.model)
      .subscribe(
        result => {
          if(result.messageCode==2){
            this.toastr.error('', result.message);
            this.model.FirstField= obj;
          }else{
          this.Id = result.data.Id;
          this.model.Id = result.data.Id;
          this.model.FirstField= obj;
          this.toastr.success('', result.message);
          }
        })
  }

  checkDuplicateFolder() {
    this.isDuplicate = true;
  
    var obj=this.model.FirstField;
    this.model.FirstField= obj;
    if (this.model.DocumentCategory > 0 && this.model.FolderName) {
      
      this.dataService.checkDuplicateFolder(this.model)
        .subscribe(
          result => {
            if (result.data > 0) {
              this.isDuplicate = true;
              this.model.FirstField= obj;
              this.toastr.error('', result.message);
            }else{
              this.isDuplicate = false;
            }
          })
    }
  }
  setFirstColumn() {
  ///  console.log(this.model.FirstField);
    let filteredColumn = this.documentRequiredFieldList.filter(field => field.Id === this.model.FirstField.Id && field.FieldColumn === 1)[0];
    if (filteredColumn)
      this.columnName1 = filteredColumn.FieldName;
      if(this.model.FirstField.FieldType==1){
        this.isDate=1
      }else{
        this.isDate=0;
      }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
