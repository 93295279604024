import { Component, OnInit, Input} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-project-miscellaneous',
  templateUrl: './project-miscellaneous.component.html',
  styleUrls: ['./project-miscellaneous.component.css']
})
export class ProjectMiscellaneousComponent implements OnInit {
  pmodel: any;
  projectId: string;
  updateText="Update";
  isEdit=false;
  isCancel=false;
  isAdd=true;
  isAdded=false;
  misBorderClass='borderGreen';
  public myDatePickerOptions: IMyDpOptions = {
    editableDateField:false,
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek:'su'
  };
  constructor(private projectService: ProjectService, private toastr: ToastrService) { 
    if(localStorage.getItem("projectId")!=""){
      this.projectId= localStorage.getItem("projectId") 
    }
  }

  ngOnInit() {
    this.getProjectMiscellaneous();
  }
  addNewRow()
  {
   this.pmodel.push({
     Id: 0,
     ProjectId:  this.projectId,
     ItemName:"",
     MiscDateCompleted:"",
     MiscDateCompletedString: ""
 });
 this.isAdd=false;
 this.isAdded=true;
 this.isEdit = true;
  }
  getProjectMiscellaneous()
  {
    this.projectService.getProjectMiscellaneous({ projectId: this.projectId})
    .subscribe(
      
      result => {
        this.misBorderClass='borderGreen';
        this.pmodel= result.data
           this.pmodel.forEach(item => {
             item.MiscDateCompleted =item.MiscDateCompletedString!=''? {jsdate: new Date(item.MiscDateCompletedString)}:'';
             if(item.ItemName=='' || item.MiscDateCompletedString=='')
              this.misBorderClass='borderRed';             
         });
        if(result.data[0].Id)    
        {
          this.isEdit = false;
        }
        else
        {
          this.updateText="Save";
          this.isEdit = false;
          this.isCancel = false;
        }
        
       })
  }
 
  saveProjectMiscellaneous()
  {
     let updateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id; 
    
     let projectMiscellaneousItems = [];
     this.pmodel.forEach(item => {
       let projectMiscellaneousItem ={
         Id: item.Id,
         ProjectId:  this.projectId,
         UpdateBy:updateBy,
         ItemName:item.ItemName,        
         MiscDateCompleted: item.MiscDateCompleted ? new Date(item.MiscDateCompleted.jsdate).toLocaleDateString():'' 
       }
       projectMiscellaneousItems.push(projectMiscellaneousItem);
   });
     
     this.projectService.saveProjectMiscellaneous(projectMiscellaneousItems)
    .subscribe(
      result => {
        this.getProjectMiscellaneous();
        this.toastr.success('', result.message);
        this.isEdit=true;
        this.isCancel = false;
        this.isAdd=true;
        this.isAdded=false;
      })
  }
 
  editProjectMiscellaneous()
  {
    this.updateText="Update";
    this.isEdit = true;
    this.isCancel = true;
    this.isAdd=false;
  }
  cancel(){
    this.getProjectMiscellaneous();
    this.isEdit = false;
    this.isAdd=true;
  }
}
