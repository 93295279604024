import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent }      from './components/home/home.component';
import { LoginComponent }      from './components/login/login.component';
import { ProjectlistComponent }      from './components/projectlist/projectlist.component';
import { ProjectdetailComponent }      from './components/projectdetail/projectdetail.component';
import { PsaleaseComponent }      from './components/psalease/psalease.component';
import { UsersComponent }      from './components/users/users.component';
import { ContacttypeComponent }      from './components/contacttype/contacttype.component';
import { SitecontactComponent }      from './components/sitecontact/sitecontact.component';
import { DocumentlistComponent }      from './components/documentlist/documentlist.component';
import { DocumentdetailComponent }      from './components/documentdetail/documentdetail.component';
import { TenantComponent }      from './components/tenant/tenant.component';
import { EntitlementComponent }      from './components/entitlement/entitlement.component';
import { ConstructionComponent } from './components/construction/construction.component';
import { CloseoutComponent } from './components/closeout/closeout.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ActivitylogComponent } from './components/activitylog/activitylog.component';
import { ProfileComponent } from './components/profile/profile.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent,canActivate: [AuthGuard]  },
  { path: 'projects/:ptype', component: ProjectlistComponent },
  { path: 'completeprojects/:ptype', component: ProjectlistComponent },
  { path: 'deadprojects/:ptype', component: ProjectlistComponent },
  { path: 'createuser', component: UsersComponent },
  { path: 'users', component: UsersComponent },
  { path: 'projectdetail', component: ProjectdetailComponent },
  { path: 'psalease', component: PsaleaseComponent },
  { path: 'contacttype', component: ContacttypeComponent },
  { path: 'sitecontact', component: SitecontactComponent },
  { path: 'documentlist', component: DocumentlistComponent },
  { path: 'documentdetail/:id', component: DocumentdetailComponent },

  { path: 'tenant', component: TenantComponent },
  { path: 'entitlement', component: EntitlementComponent },
  { path: 'construction', component: ConstructionComponent },
  { path: 'closeout', component: CloseoutComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'activitylog', component: ActivitylogComponent },
  { path: 'profile', component:ProfileComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
 
  exports: [RouterModule]
})
export class AppRoutingModule { }
