import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  title = 'HummelInvestmentApp';

  constructor(private permissionsService: NgxPermissionsService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {


    if (localStorage.getItem('isLoggedin')) {
      this.authenticationService.getUserAccessList({ Id: JSON.parse(localStorage.getItem('currentUser')).data.Id })
        .subscribe(
          result => {
            if (result.messageCode == 1) {
              this.permissionsService.loadPermissions(result.userAccess);
            }
          })
    }
  }
}