import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map'
import { map } from 'rxjs/operators'
import 'rxjs/add/observable/timer';;
import { AppSettings } from '../app-Settings';
let ApiUrl = AppSettings.API_ENDPOINT;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': '"multipart/form-data"' })
};
//import { HttpResponse } from 'selenium-webdriver/http';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient, private route: ActivatedRoute,
    private router: Router) { }

  createUser(params) {
    return this.http.post<any>(ApiUrl + 'User/createUser', params)
      .map(data => {
        return data;
      });

  }

  deleteUser(params) {
    return this.http.post<any>(ApiUrl + 'User/deleteUser', params)
      .map(data => {
        return data;
      });
  }
  getUser(params) {

    return this.http.post<any>(ApiUrl + 'User/getUsers', params)
      .map(data => {
        return data;
      });
  }



  createContactType(params) {
    return this.http.post<any>(ApiUrl + 'Contact/createContactType', params)
      .map(data => {
        return data;
      });
  }
  getContactType(params) {

    return this.http.post<any>(ApiUrl + 'Contact/getContactType', params)
      .map(data => {
        return data;
      });
  }
  deleteContactType(params) {
    return this.http.post<any>(ApiUrl + 'Contact/deleteContactType', params)
      .map(data => {
        return data;
      });
  }
  getUserAccess(params) {

    return this.http.post<any>(ApiUrl + 'User/getUserAccess', params)
      .map(data => {
        return data;
      });
  }


  createSiteContact(params) {
    return this.http.post<any>(ApiUrl + 'Contact/createSiteContact', params)
      .map(data => {
        return data;
      });
  }
  getSiteContactList(params) {

    return this.http.post<any>(ApiUrl + 'Contact/getSiteContact', params)
      .map(data => {
        return data;
      });
  }


  getSiteContact(params) {
    return this.http.get<any>(ApiUrl + 'Contact/createUser?id=' + params)
      .map(data => {
        return data;
      });
  }

  deleteSiteContact(params) {
    return this.http.post<any>(ApiUrl + 'Contact/deleteSiteContact', params)
      .map(data => {
        return data;
      });
  }

  createProjectData(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveProjectData', params)
      .map(data => {
        return data;
      });
  }
  editProjectData(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/createContactType', params)
      .map(data => {
        return data;
      });
  }

  getProjectDataList(obj) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getProjectDataList',obj)
      .map(data => {
        return data;
      });
  }


  getProjectData(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getProjectData', params)
      .map(data => {
        return data;
      });
  }


  createNotes(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveNotes', params)
      .map(data => {
        return data;
      });
  }


  getNotesList(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getNotesList', params)
      .map(data => {
        return data;
      });
  }

  deleteNotes(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/deleteNotes', params)
      .map(data => {
        return data;
      });
  }

  saveSitePhoto(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveSitePhoto', params)
      .map(data => {
        return data;
      });
  }

  saveSiteDocument(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveSiteDocument', params)
      .map(data => {
        return data;
      });
  }
  getSitePhoto(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getSitePhoto', params)
      .map(data => {
        return data;
      });
  }
  getSiteDocument(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getSiteDocument', params)
      .map(data => {
        return data;
      });
  }
  getSiteData(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getSiteData', params)
      .map(data => {
        return data;
      });
  }

  saveSiteData(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveSiteData', params)
      .map(data => {
        return data;
      });
  }


  getSiteDemographics(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getSiteDemographics', params)
      .map(data => {
        return data;
      });
  }

  saveSiteDemographics(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveSiteDemographics', params)
      .map(data => {
        return data;
      });
  }
  getClient(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getClient', params)
      .map(data => {
        return data;
      });
  }

  getDocumentCategory(params) {
    return this.http.post<any>(ApiUrl + 'Document/getDocumentCategory', params)
      .map(data => {
        return data;
      });
  }

  getDocumentRequiredField(params) {
    return this.http.post<any>(ApiUrl + 'Document/getDocumentRequiredField', params)
      .map(data => {
        return data;
      });
  }

  saveDocumentFolder(params) {
    return this.http.post<any>(ApiUrl + 'Document/saveDocumentFolder', params)
      .map(data => {
        return data;
      });
  }

  checkDuplicateFolder(params) {
    console.log(params)
    return this.http.post<any>(ApiUrl + 'Document/checkDuplicateFolder', params)
      .map(data => {
        return data;
      });
  }

  checkContactEmailAvailability(params) {
    return this.http.post<any>(ApiUrl + 'Contact/checkContactEmail', params)
      .map(data => {
        return data;
      });
  }
  checkcheckUserNameAvailability(params) {
    return this.http.post<any>(ApiUrl + 'User/checkUserName', params)
      .map(data => {
        return data;
      });
  }
  getDocumentFolder(params) {
    return this.http.post<any>(ApiUrl + 'Document/getDocumentFolder', params)
      .map(data => {
        return data;
      });
  }

  deleteDocumentFolder(params) {
    return this.http.post<any>(ApiUrl + 'Document/deleteDocumentFolder', params)
      .map(data => {
        return data;
      });
  }

  getDocumentList(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getDocumentList', params)
      .map(data => {
        return data;
      });
  }
  updateDocumentList(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveDocumennt', params)
      .map(data => {
        return data;
      });
  }

  deletePhoto(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/deleteSitePhoto', params)
      .map(data => {
        return data;
      });
  }

  saveDocumentFile(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/saveDocumentFile', params)
      .map(data => {
        return data;
      });
  }
  deleteDocumentFile(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/deleteDocumentFile', params)
      .map(data => {
        return data;
      });
  }
  getDocumentFileList(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/GetDocumentFile', params)
      .map(data => {
        return data;
      });
  }
  getAcquisitionCriticalList() {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getAcquisitionCriticalList', {})
      .map(data => {
        return data;
      });
  }
  getConstructionList() {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getProjectUnderConstructionList', {})
      .map(data => {
        return data;
      });
  }
  getUserType() {

    return this.http.post<any>(ApiUrl + 'User/getUserType', {})
      .map(data => {
        return data;
      });
  }
  getContacts(params) {

    return this.http.post<any>(ApiUrl + 'Contact/getContacts', params)
      .map(data => {
        return data;
      });
  }

  getProjectContactList(projectID) {
    return this.http.get<any>(ApiUrl + 'Contact/getProjectContactsList?PageNumber=1&RecordCount=50&ProjectID=' + projectID)
      .map(data => {
        return data;
      });
  }

  createProjectContact(params) {

    return this.http.post<any>(ApiUrl + 'Contact/createProjectContact', params)
      .map(data => {
        return data;
      });
  }
  ProjectContactsList(params) {

    return this.http.post<any>(ApiUrl + 'Contact/getProjectContactsList', params)
      .map(data => {
        return data;
      });
  }
  deleteProjectContact(params) {

    return this.http.post<any>(ApiUrl + 'Contact/deleteProjectContact', params)
      .map(data => {
        return data;
      });
  }

deleteProject(params)
{
  return this.http.post<any>(ApiUrl + 'SiteProfile/deleteProject', params)
  .map(data => {
    return data;
  });
}



  getContactTypeWithclient(params) {

    return this.http.post<any>(ApiUrl + 'Contact/getContactTypeWithclient', params)
      .map(data => {
        return data;
      });
  }
  getContactTypeWithoutclient(params) {

    return this.http.post<any>(ApiUrl + 'Contact/getContactTypeWithoutclient', params)
      .map(data => {
        return data;
      });
  }
  getStatus(params) {
    return this.http.post<any>(ApiUrl + 'SiteProfile/getStatus', params)
      .map(data => {
        return data;
      });
  }
  sendMailToProjectContact(params) {

    return this.http.post<any>(ApiUrl + 'Contact/sendContactEmail', params)
      .map(data => {
        return data;
      });
  }
}


