import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  model: any = {};
  isShowEdit = false;
  isUpdate = false;
  formData = new FormData();
  StatusList: any;
  Status: "";
  Project_Status = ""
  isImage = false
  disableButton=false
  @Input() id: number;
  @Input() NotesType: number;
  @Input() title: string;
  
  noteId=0;
  notesList:any
  public editor;
  public editorContent = `<h3>I am Example content</h3>`;
  public editorOptions = {
    placeholder: "insert content..."
  };
  constructor(private dataService: DataService, private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService) { }
  onEditorBlured(quill) {
    console.log('editor blur!', quill);
  }
 
  onEditorFocused(quill) {
    console.log('editor focus!', quill);
  }
 
  onEditorCreated(quill) {
    this.editor = quill;
    console.log('quill is ready! this is current quill instance object', quill);
  }
 
  onContentChanged({ quill, html, text }) {
    console.log('quill content is changed!', quill, html, text);
  }
  ngOnInit() {
    if (this.id != 0) {
     this.bindNotes();
    }
    // setTimeout(() => {
    //   this.editorContent = '<h1>content changed!</h1>';
    //   console.log('you can use the quill instance object to do something', this.editor);
    //   // this.editor.disable();
    // }, 2800)
  }

bindNotes(){
  if(localStorage.getItem("projectId")!=""){
    this.id= parseInt(localStorage.getItem("projectId"))
  }
  this.dataService.getNotesList({ ProjectID: this.id,NotesType:this.NotesType })
  .subscribe(
    result => {
      this.notesList=result.data;
      
    })

}
edit(data:any){
  this.isShowEdit = true;
  this.isUpdate = true;
  this.noteId=data.Id;
  this.model.Description=data.Description
}
  addNote(){
    this.noteId=0;
    this.model.Description="";
    this.isShowEdit = true;
    this.isUpdate = false;
    this.disableButton = false;
  }
  save(){
    this.disableButton = true;
    this.model.ProjectId= this.id
    if(localStorage.getItem("projectId")!=""){
      this.model.ProjectId= localStorage.getItem("projectId")
    }
    this.model.Id=0;
    this.model.NotesType= this.NotesType;
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
   
    this.dataService.createNotes(this.model)
   
    .subscribe(
      result => {
        this.toastr.success('', result.message);
        this.bindNotes();
        this.isShowEdit = false;
      })
  }
  update(){
    if(localStorage.getItem("projectId")!=""){
      this.id= parseInt(localStorage.getItem("projectId"))
    }
    this.model.Id=this.noteId;
    this.model.ProjectID= this.id;
    this.model.NotesType= this.NotesType;
    this.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.dataService.createNotes(this.model)
    .subscribe(
      result => {
        this.bindNotes();
        this.toastr.success('', result.message);
        this.isShowEdit = false;
      })
  }
  cancel(){
    this.isShowEdit = false;
  }

  delete(data:any){
    let self = this;
    self.model.Id =data.Id;
    self.model.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    self.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this note?')
    .then(function(confirmed){
      if(confirmed){
        self.dataService.deleteNotes(self.model)
    .subscribe(
      result => {
        self.bindNotes();
        self.toastr.success('', result.message);
        self.isShowEdit = false;
      })

    }
  });
  }
}
