import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { LocationStrategy,Location } from '@angular/common';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
model:any={}
constructor(private dataService: DataService, private router: Router, private toastr: ToastrService,private _location: Location) { }

  ngOnInit() {

    this.dataService.getUser({Id:JSON.parse(localStorage.getItem('currentUser')).data.Id})
    .subscribe(
      result => {
        this.model.FirstName = result.data[0].FirstName;
        this.model.LastName = result.data[0].LastName;
        this.model.Email = result.data[0].Email;
        this.model.UserName = result.data[0].UserName;
        this.model.Password = result.data[0].Password;
        this.model.rePassword = result.data[0].Password;

        this.model.UserType = result.data[0].UserType;
        this.model.Status =  result.data[0].Status;
      })
  }

  update(){
    if (this.model.Email == undefined || this.model.Email == "") {
      this.toastr.error('', 'Please enter your email.');
      return
    }
    else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.Email)) {
        this.toastr.error('', 'Please enter valid email.');
        return
      }
    }
    if (this.model.FirstName == undefined || this.model.FirstName == "") {
      this.toastr.error('', 'Please enter your first name.');
      return
    }
    if (this.model.LastName == undefined || this.model.LastName == "") {
      this.toastr.error('', 'Please enter your last name.');
      return
    }
    if (this.model.Password == undefined || this.model.Password == "") {
      this.toastr.error('', 'Please enter your password.');
      return
    }
    if (this.model.rePassword == undefined || this.model.rePassword == "") {
      this.toastr.error('', 'Please enter your rePassword.');
      return
    }
    if (this.model.Password != this.model.rePassword) {
      this.toastr.success('', 'Password does not matches.');
      return
    }
    this.model.Phone = 46464646;
    this.model.Fax = 46464646;

    //this.model.UserType = this.UserType;
   // this.model.Status = this.Status;
    this.model.Id = JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.dataService.createUser(this.model)
      .subscribe(
        result => {
         
          this.toastr.success('', "Profile updated successfully.");
         
        })

  }

  cancel(){
    this._location.back();
   // this.router.navigate(['/'])
  }
}
