import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import{CurrencyPipe  }from'@angular/common';
@Component({
  selector: 'app-sitedata',
  templateUrl: './sitedata.component.html',
  styleUrls: ['./sitedata.component.css']
})
export class SitedataComponent implements OnInit {
  model: any = {};
  isEdit = true;
  isUpdate = false;
  @Input() id: number;
 constructor(private dataService: DataService, private toastr: ToastrService,private currencyPipe:CurrencyPipe) {
  
  }

  ngOnInit() {

    this.getSiteData();

  }

  getSiteData()
  {
    if(localStorage.getItem("projectId")!="0"){
      this.id=parseInt(localStorage.getItem("projectId"))
    }
    this.dataService.getSiteData({ projectId: this.id })
    .subscribe(
      result => {
        this.model.Id= result.data.Id
        this.model.ProjectId = this.id 
        this.model.DealType = result.data.DealType
        this.model.Purchase_GL = result.data.Purchase_GL
        this.model.SiteType = result.data.SiteType
        this.model.SiteArea_SQFT = result.data.SiteArea_SQFT<=0?'':result.data.SiteArea_SQFT
        this.model.SiteArea_Acres = result.data.SiteArea_Acres<=0?'':result.data.SiteArea_Acres
        this.model.LandPrice = result.data.LandPrice<=0?'':result.data.LandPrice
        this.model.LandPrice_SQFT = result.data.LandPrice_SQFT<=0?'':result.data.LandPrice_SQFT
        this.model.NewOrRelo = result.data.NewOrRelo
        this.model.LandPriceString = result.data.LandPriceString.split(".")[0]
        this.model.LandPrice_SQFTString = result.data.LandPrice_SQFTString;
        this.model.DealTypeString = result.data.DealTypeString
        this.model.Purchase_GLString = result.data.Purchase_GLString
        this.model.SiteTypeString = result.data.SiteTypeString
        
        this.model.SiteArea_SQFTview= this.numberWithCommas(result.data.SiteArea_SQFT) ;

        if(result.data.Id)    
        {
          this.isEdit = false;
        }
        else
        {
          this.model.DealType=1;
          this.model.SiteType=1;
          this.model.Purchase_GL=1;
          this.model.UpdateText="Save";
          this.isEdit = false;
        }
       })
  }

  SaveSiteData()
  {
    if(localStorage.getItem("projectId")!=""){
      this.model.ProjectId= localStorage.getItem("projectId")
    }
    this.model.UpdateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id;   
    this.dataService.saveSiteData(this.model)
    .subscribe(
      result => {
        this.getSiteData();
        this.toastr.success('', result.message);
        this.isEdit=true;
      })
  }

  EditSiteData()
  {
    this.model.UpdateText="Update";
    this.isEdit = true;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberdecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode
    var value = event.target.value;
    var dotcontains = value.indexOf(".") != -1;
    if (dotcontains)
        if (charCode == 46) return false;
    if (charCode == 46) return true;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }
  
  validDecimal(txt) {
    return txt.match(/^\d*\.?\d{0,3}$/);
  }
  transformSiteArea(element){
    // if(this.model.SiteArea_SQFT!=""){
    //  var num = this.currencyPipe.transform(this.model.SiteArea_SQFT, 'USD').replace(/[$]/g, "");
    //  element.target.value = num;
    // }
    element.target.value = this.numberWithCommas(this.model.SiteArea_SQFT)
   }
    numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
}
