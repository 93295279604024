
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-viewlink',
  templateUrl: './viewlink.component.html',
  styleUrls: ['./viewlink.component.css']
})
export class ViewlinkComponent implements ViewCell, OnInit {

  renderValue: string;
  ptype=0;
  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private route: Router,private routes: ActivatedRoute
    //  private userService: UserService,
    // private alertService: AlertService,
    // public toastr: ToastrService,
  ) {
    this.routes.params.subscribe(params => {
      if (params['ptype']) {
        this.ptype = params['ptype']
      }
    });
   }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    
    localStorage.setItem("projectId",this.rowData.Id);
  

    var previousList={};
    if(this.ptype==1){
      previousList={
        listType:"Current Projects",
        nav:"projects/1"
      }
    }else if(this.ptype==2){
      previousList={
        listType:"Complete Projects",
        nav:"completeprojects/2"
      }
    }else{
      previousList={
        listType:"Dead Projects",
        nav:"deadprojects/3"
      }
    }
 
    localStorage.setItem("projectType",  JSON.stringify(previousList));
    this.route.navigate(['/projectdetail'])
  }
}
