import { Component, OnInit, Input,EventEmitter, Output  } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { IMyDpOptions } from 'mydatepicker';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
@Component({
  selector: 'app-projectdata',
  templateUrl: './projectdata.component.html',
  styleUrls: ['./projectdata.component.css']
})

export class ProjectdataComponent implements OnInit {
  model: any = {};
  isShowEdit = true;
  isUpdate = false;
  formData = new FormData();
  StatusList: any;
  Status: "";
  Project_Status = ""
  isImage = false
  selectelm = {  Name: "Select", Id: 0 };
  project_Image="";
  isBrowse=false;

  url = '../../assets/images/image-holder.png';
  clientList:any

  @Input() id: number;
  @Output() close: EventEmitter<any> = new EventEmitter();
  public myDatePickerOptions: IMyDpOptions = {
    editableDateField:false,
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek:'su'
  };

  // Initialized to specific date (09.10.2018).
  //public model: any = { date: { year: 2018, month: 10, day: 9 } };
  constructor(private dataService: DataService, private toastr: ToastrService, private _sanitizer: DomSanitizer) {
    // this.model.ProposedOpening={ date: { year: 2018, month: 10, day: 9 } };
  }

  ngOnInit() {

    this.dataService.getClient({ })
    .subscribe(
      result => {
        this.clientList = result.data
        
        this.clientList.unshift(this.selectelm);
        
      })

    if (this.id == 0) {
      this.isShowEdit = false;
      this.model.Client = 0;
    } else {
     this.getProjectData();
    }
    
  this.dataService.getStatus({})
  .subscribe(
    result => {
      this.StatusList=result.data;
    });
  }
  getProjectData(){
    this.dataService.getProjectData({ Id: this.id })
    .subscribe(
      result => {
        this.model.Client = result.data.Client;
        this.model.ClientName = result.data.ClientName;
        this.model.City = result.data.City
        this.model.State = result.data.State
        this.model.MSA = result.data.MSA
        this.model.Store = result.data.Store
        this.model.SiteId = result.data.SiteId
        this.model.Zip = result.data.Zip
        // this.model.ProposedTo = result.data.ProposedTo
        this.model.OXBlueCameraFeed = result.data.OXBlueCameraFeed
        this.model.Intersection = result.data.Intersection
        this.model.Address = result.data.Address
        this.Project_Status = result.data.Project_Status;

         this.model.OXBlueCameraFeedHtml =  this._sanitizer.bypassSecurityTrustHtml(result.data.OXBlueCameraFeed);
        //this.model.OXBlueCameraFeedHtml = this._sanitizer.sanitize(SecurityContext.HTML, result.data.OXBlueCameraFeed);
        if (result.data.ProposedTo != "") {
          var datePart = result.data.ProposedTo.split(" ")[0];
          var day = datePart.split("/")[1];
          var month = datePart.split("/")[0];
          var year = datePart.split("/")[2];
          this.model.ProposedTo = { date: { year: year, month: month, day: day } };
         this.model.viewProposedTo=month+"/"+day+"/"+year;
        }
        if (result.data.ProposedOpening != "") {
          var datePart = result.data.ProposedOpening.split(" ")[0];
          var day = datePart.split("/")[1];
          var month = datePart.split("/")[0];
          var year = datePart.split("/")[2];
          this.model.ProposedOpening = { date: { year: year, month: month, day: day } };
        this.model.viewProposedOpening=month+"/"+day+"/"+year;
        }
        if (result.data.REC_Approval != "") {
          var datePart = result.data.REC_Approval.split(" ")[0];
          var day = datePart.split("/")[1];
          var month = datePart.split("/")[0];
          var year = datePart.split("/")[2];
          this.model.REC_Approval = { date: { year: year, month: month, day: day } };
          this.model.viewREC_Approval=month+"/"+day+"/"+year;
        }
        if (result.data.REC_Submitted != "") {
          var datePart = result.data.REC_Submitted.split(" ")[0];
          var day = datePart.split("/")[1];
          var month = datePart.split("/")[0];
          var year = datePart.split("/")[2];
          this.model.REC_Submitted = { date: { year: year, month: month, day: day } };
          this.model.viewREC_Submitted=month+"/"+day+"/"+year;
        }
        this.isImage=true;
       // this.url =result.data.Project_ImageVirtualUrl;
        //this.formData.append("Project_Image", result.data.Project_Image);
        this.project_Image=result.data.Project_Image;
        var imageUrl = result.data.Project_ImageVirtualUrl;
        var self=this
        this.imageExists(imageUrl, function(exists) {
       if(exists==false){
          self.isImage=false;
       }
          self.url =result.data.Project_ImageVirtualUrl;
        
        });

      })
      this.isBrowse=false;
  }
  ngAfterViewInit() {

  }
   imageExists(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true); };
    img.onerror = function() { callback(false); };
    img.src = url;
  }
  
  // Sample usage
 

  edit() {
    this.isShowEdit = false;
    this.isUpdate = true;

  }
  save() {
    
    if (this.model.Client == undefined || this.model.Client == "") {
      this.toastr.error('', 'Please enter your Client.');
      return
    }
    if (this.model.City == undefined || this.model.City == "") {
      this.toastr.error('', 'Please enter your City.');
      return
    }
 
    

    if (this.model.MSA == undefined || this.model.MSA == "") {
      this.model.MSA ="";
      //this.toastr.error('', 'Please enter your MSA.');
      //return
     }
   
    // if (this.model.SiteId == undefined || this.model.SiteId == "") {
      
    // }
    if (this.model.ProposedTo == undefined || this.model.ProposedTo == "") {

      this.model.ProposedTo = "";
      // this.toastr.error('', 'Please enter your ProposedTo.');
      // return
    }
    if (this.model.REC_Submitted == undefined || this.model.REC_Submitted == "") {

      this.model.REC_Submitted = "";
      // this.toastr.error('', 'Please enter your ProposedTo.');
      // return
    }
    if (this.model.REC_Approval == undefined || this.model.REC_Approval == "") {

      this.model.REC_Approval = "";
      // this.toastr.error('', 'Please enter your ProposedTo.');
      // return
    }
    if (this.model.ProposedOpening == undefined || this.model.ProposedOpening == "") {

      this.model.ProposedOpening = "";
      // this.toastr.error('', 'Please enter your ProposedTo.');
      // return
    }
    if (this.model.Intersection == undefined || this.model.Intersection == "") {
      this.toastr.error('', 'Please enter your Intersection.');
      return
    }
    if (this.model.Address == undefined || this.model.Address == "") {
      this.toastr.error('', 'Please enter your Address.');
      return
    }
    if (this.model.Zip == undefined || this.model.Zip == "") {
      this.toastr.error('', 'Please enter zip.');
      return
     }
    if (this.Project_Status == undefined || this.Project_Status == "") {
      this.toastr.error('', 'Please enter status');
       return
      }


    this.formData.append('Id', "0");
    this.formData.append('Client', this.model.Client);
    this.formData.append('City', this.model.City)
    this.formData.append('State', this.model.State? this.model.State:'')
    this.formData.append('MSA', this.model.MSA)
    this.formData.append('Zip', this.model.Zip)
    this.formData.append('Store', this.model.Store?this.model.Store:'')
    this.formData.append('SiteId', this.model.SiteId?this.model.SiteId:'')
    //this.formData.append('ProposedTo', this.model.ProposedTo)
    this.formData.append('OXBlueCameraFeed', this.model.OXBlueCameraFeed?this.model.OXBlueCameraFeed:'')
    this.formData.append('Intersection', this.model.Intersection);
    this.formData.append('Address', this.model.Address)
    //this.formData.append('REC_Submitted', this.model.REC_Submitted)
    //this.formData.append('REC_Approval', this.model.REC_Approval)

    if (this.model.ProposedTo != undefined && this.model.ProposedTo != "") {
      var date = this.model.ProposedTo.date;
      var dateForamate =  this.model.ProposedTo.date.month + "-"  + this.model.ProposedTo.date.day + "-" +  this.model.ProposedTo.date.year;
      this.formData.append('ProposedTo', dateForamate)
    }
    if (this.model.ProposedOpening != undefined && this.model.ProposedOpening != "") {
      var date = this.model.ProposedOpening.date;
      var dateForamate =  this.model.ProposedOpening.date.month + "-" +  this.model.ProposedOpening.date.day + "-" + this.model.ProposedOpening.date.year;
      this.formData.append('ProposedOpening', dateForamate)
    }
    if (this.model.REC_Submitted != undefined && this.model.REC_Submitted != "") {
      var date = this.model.REC_Submitted.date;
      var dateForamate =  this.model.REC_Submitted.date.month + "-" + this.model.REC_Submitted.date.day + "-" + this.model.REC_Submitted.date.year;
      this.formData.append('REC_Submitted', dateForamate)
    }
    if (this.model.REC_Approval != undefined && this.model.REC_Approval != "") {
      var date = this.model.REC_Approval.date;
      var dateForamate =  this.model.REC_Approval.date.month + "-" + this.model.REC_Approval.date.day + "-" + this.model.REC_Approval.date.year;
      this.formData.append('REC_Approval', dateForamate)
    }


    this.formData.append('Project_Status', this.Project_Status)

    //this.formData.append('ProposedTo', this.model.ProposedTo)
    
    var prid=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    
    this.formData.append('UpdateBy', prid)
    this.dataService.createProjectData(this.formData)
      .subscribe(
        result => {
          this.id=result.data.Id;
          this.getProjectData();
          this.toastr.success('', result.message);
          localStorage.setItem("projectId",result.data.Id);
         
          this.isShowEdit = true;
          this.isUpdate = false;
        
          this.close.emit(null);

        })

  }
  update() {
    if (this.model.Client == undefined || this.model.Client == "") {
      this.toastr.error('', 'Please enter your Client.');
      return
    }
    if (this.model.City == undefined || this.model.City == "") {
      this.toastr.error('', 'Please enter your City.');
      return
    }
   
     if (this.model.MSA == undefined || this.model.MSA == "") {
      this.model.MSA ="";
      //this.toastr.error('', 'Please enter your MSA.');
      //return
     }
    // if (this.model.Store == undefined || this.model.Store == "") {
    //   this.toastr.error('', 'Please enter your Store.');
    //   return
    // }
    // if (this.model.SiteId == undefined || this.model.SiteId == "") {
    //   this.toastr.error('', 'Please enter your SiteId.');
    //   return
    // }
    // if (this.model.ProposedTo == undefined || this.model.ProposedTo == "") {
    //   this.toastr.error('', 'Please enter your ProposedTo.');
    //   return
    // }
    if (this.model.Intersection == undefined || this.model.Intersection == "") {
      this.toastr.error('', 'Please enter your Intersection.');
      return
    }
    if (this.model.Address == undefined || this.model.Address == "") {
      this.toastr.error('', 'Please enter your Address.');
      return
    }
    if (this.model.Zip == undefined || this.model.Zip == "") {
      this.toastr.error('', 'Please enter zip.');
      return
     }
    if (this.Project_Status == undefined || this.Project_Status == "") {
      this.toastr.error('', 'Please enter status');
       return
      }
      if(!this.isBrowse)
       {
       this.formData = new FormData;
      }
    this.formData.append("Project_Image", this.project_Image);
    this.formData.append('Id', this.id.toString());
    this.formData.append('Client', this.model.Client);
    this.formData.append('City', this.model.City)
    this.formData.append('State', this.model.State? this.model.State:'')
    this.formData.append('MSA', this.model.MSA)
    this.formData.append('Zip', this.model.Zip)
    this.formData.append('Store', this.model.Store?this.model.Store:'')
    this.formData.append('SiteId', this.model.SiteId?this.model.SiteId:'')
    //   this.formData.append('ProposedTo', this.model.ProposedTo)
    this.formData.append('OXBlueCameraFeed', this.model.OXBlueCameraFeed?this.model.OXBlueCameraFeed:'')
    this.formData.append('Intersection', this.model.Intersection);
    this.formData.append('Address', this.model.Address)
    // this.formData.append('REC_Submitted', this.model.REC_Submitted)
    //  this.formData.append('REC_Approval', this.model.REC_Approval)
    //  this.formData.append('ProposedOpening', this.model.ProposedOpening)
    // this.formData.append('Project_Status', "1")
    var prid=JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.formData.append('Project_Status', this.Project_Status)
    this.formData.append('UpdateBy', prid)

    if (this.model.ProposedTo != undefined && this.model.ProposedTo != "") {
      var date = this.model.ProposedTo.date;
      var dateForamate =  this.model.ProposedTo.date.month + "-"  + this.model.ProposedTo.date.day + "-" +  this.model.ProposedTo.date.year;
      this.formData.append('ProposedTo', dateForamate)
    }
    if (this.model.ProposedOpening != undefined && this.model.ProposedOpening != "") {
      var date = this.model.ProposedOpening.date;
      var dateForamate =  this.model.ProposedOpening.date.month + "-" +  this.model.ProposedOpening.date.day + "-" + this.model.ProposedOpening.date.year;
      this.formData.append('ProposedOpening', dateForamate)
    }
    if (this.model.REC_Submitted != undefined && this.model.REC_Submitted != "") {
      var date = this.model.REC_Submitted.date;
      var dateForamate =  this.model.REC_Submitted.date.month + "-" + this.model.REC_Submitted.date.day + "-" + this.model.REC_Submitted.date.year;
      this.formData.append('REC_Submitted', dateForamate)
    }
    if (this.model.REC_Approval != undefined && this.model.REC_Approval != "") {
      var date = this.model.REC_Approval.date;
      var dateForamate =  this.model.REC_Approval.date.month + "-" + this.model.REC_Approval.date.day + "-" + this.model.REC_Approval.date.year;
      this.formData.append('REC_Approval', dateForamate)
    }

    this.dataService.createProjectData(this.formData)
      .subscribe(
        result => {

          this.formData = new FormData;
          this.getProjectData();
          this.toastr.success('', result.message);
          this.isShowEdit = true;
          this.isUpdate = false;
        })
  }
  readUrl(event: any) {
    this.formData = new FormData
    this.isBrowse=true;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.isImage = true;
      reader.onload = (event: any) => {
        
        this.url = event.target.result;
      }
      let files = event.target.files;
      this.formData.append("Project_Image", files[0]);
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
