import { Component, OnInit ,Input} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppSettings} from '../../app-Settings';
import { fail } from 'assert';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
let ApiUrl = AppSettings.API_ENDPOINT;

@Component({
  selector: 'app-projectteam',
  templateUrl: './projectteam.component.html',
  styleUrls: ['./projectteam.component.css']
})
export class ProjectteamComponent implements OnInit {
  contactTypeList: any;
  contactList: any;
  projectContactList:any;
  member:"";
  ContactType=0;
  Contact=0;
  isShowEdit = false;
  settings: any;
  data: ServerDataSource
  @Input() id: number;
  constructor(private http: HttpClient,private dataService: DataService, private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService) { 

 
    this.settings = {
      columns: {

        Name: {
          title: 'Name',
          filter: false
        },
        ContactType: {
          title: 'Contact Type',
          filter: false
        },
        Company: {
          title: 'Company',
          filter: false
        },
        Email: {
          title: 'Email',
          filter: false
        },
        Cell: {
          title: 'Cell',
          filter: false
        },
        Phone1: {
          title: 'Phone1',
          filter: false
        },
      },

      actions: {
        edit: false,
        add: false,
        delete: false,
        custom: [
          { name: 'Delete',  title: `<span  class="btn btn-danger btn-rounded"><i class="fa fa-times"></i> Delete</span>` }

        ],
        position: 'right', // left|right
      },
      pager: {
        display: true,
        perPage: 25
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };
   }

  ngOnInit() {
    this.bindContact(0)
 this.bindProjectContactList();
    this.dataService.getContactTypeWithoutclient({})
      .subscribe(
        result => {
          this.contactTypeList = result.data;

        })
  }

bindContact(ContactType){
  this.dataService.getContacts({ContactType:ContactType,ProjectID:this.id})
      .subscribe(
        result => {
          this.contactList = result.data;

        })
}
bindProjectContactList(){
  this.data = new ServerDataSource(this.http, {
    //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
    endPoint: ApiUrl + 'Contact/getProjectContactsList?ProjectID=' + this.id,
    // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
    dataKey: 'data.data',
    totalKey: 'TotalCount',
    pagerLimitKey: 'RecordCount',
    pagerPageKey: 'PageNumber',
    filterFieldKey: '#field#',
    sortFieldKey: 'SortColumn',
    sortDirKey: 'SortOrder',
  });
  // this.dataService.ProjectContactsList({ProjectID:this.id})
  //     .subscribe(
  //       result => {
  //         this.projectContactList = result.data;

  //       })
}
  onChange(contact) {
   this.bindContact(contact)
    console.log(contact)
  }
  add(){
    if(this.Contact==0){
      this.toastr.error('', "Please select Contact");
      return;
    }
    var model={
      ContactID:this.Contact,
      ProjectID:this.id,
      UpdateBy: JSON.parse(localStorage.getItem('currentUser')).data.Id
    }
    this.dataService.createProjectContact(model)
    .subscribe(
      result => {
        this.toastr.success('', result.message);

        this.isShowEdit = false;
        this.isShowEdit = true;
        this.ContactType=0;
        this.Contact=0;
        this.bindProjectContactList();
      })
  }
  delete(item:any){
    let self = this;
    var model={
      Id:item.Id,
      UpdateBy: JSON.parse(localStorage.getItem('currentUser')).data.Id
    }
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this contact?')
    .then(function(confirmed){
      if(confirmed){
        self.dataService.deleteProjectContact(model)
    .subscribe(
      result => {
        self.toastr.success('', result.message);
        self.bindProjectContactList();
      })
    }
  })
  }

  onCustom(event: any) {
 
    if (event.action == "Delete") {
      let self = this;
    var model={
      Id:event.data.ContactID,
      UpdateBy: JSON.parse(localStorage.getItem('currentUser')).data.Id
    }
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this contact?')
      .then(function(confirmed){
        if(confirmed){
      //this.route.navigate(['documentdetail/'+event.data.Id])
    
       self.dataService.deleteProjectContact(model)
      .subscribe(
        result => {
          if(result.messageCode)
          {
            self.data.refresh();
            self.toastr.success('', result.message);
          }
        })

      }});
    }
  }



  addTeam(){
    this.isShowEdit = true;
    this.ContactType=0;
    this.Contact=0;
  }
  cancel(){
    this.isShowEdit = false;
  }
}
