import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
import { ServerDataSource } from 'ng2-smart-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppSettings} from '../../app-Settings';
import { fail } from 'assert';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

import { Http } from '@angular/http';
let ApiUrl = AppSettings.API_ENDPOINT;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  model: any = {};
  isShowForm = false;
  isShowList = true;
  isUpdate = false;
  //isNewUser: boolean;
  accountTypeList: any;
  StatusList: any;
  UserType: any
  Status: any
  userList: any
  Id = "";
  buttonDisabled: boolean;
  userAccess: any;
  selectedModule = [];
  selectedModuleAccess = [];
  settings: any;
  data: ServerDataSource
  
  constructor(private http: HttpClient,private dataService: DataService, private router: Router, private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService) {
    this.model.UpdateBy= JSON.parse(localStorage.getItem('currentUser')).data.Id; 
    this.data = new ServerDataSource(http, {
      //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
      endPoint: ApiUrl + 'user/getUsersList?Id='+this.model.UpdateBy,
      // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
      dataKey: 'data.data',
      totalKey: 'TotalCount',
      pagerLimitKey: 'RecordCount',
      pagerPageKey: 'PageNumber',
      filterFieldKey: '#field#',
      sortFieldKey: 'SortColumn',
      sortDirKey: 'SortOrder',
    });
 
    this.settings = {
      columns: {

        UserName: {
          title: 'Username',
          filter: false
        },
        FirstName: {
          title: 'Name',
          filter: false
        },
        UserTypeName: {
          title: 'Type',
          filter: false,
         
        },
        StatusText: {
          title: 'Status',
          filter: false
        },
        
      },

      actions: {
        edit: false,
        add: false,
        delete: false,
        custom: [
          { name: 'Edit',  title: `<span  class="btn btn-info btn-rounded edit_notes"><i class="fa fa-pencil"></i> Edit</span>` },
          { name: 'Delete',  title: `<span  class="btn btn-danger btn-rounded"><i class="fa fa-times"></i> Delete</span>` }

        ],
        position: 'right', // left|right
      },
      pager: {
        display: true,
        perPage: 25
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };
   }

  ngOnInit() {
   
    this.bindUser();
    let preArray = [];

    preArray.push(
      {
        _id: 1,
        title: "Administrator"
      },
      {
        _id: 2,
        title: "Member"
      }
    )
   // this.accountTypeList = preArray;
    this.StatusList = [{
      _id: "-1",
      title: "Select"
    },{
      _id: true,
      title: "Active"
    },
    {
      _id: false,
      title: "Inactive"
    }]
this.Status="-1";
    // this.userAccess = [
    //   { Id: 1, ModuleName: "Project", selected: true, Access: [{ Id: 1, ItemName: "Upload Project Documents", AccessModuleId: 1, selected: true }, { Id: 2, ItemName: " Delete Project Documents", AccessModuleId: 1 }] },
    //   { Id: 2, ModuleName: "Admin", selected: false, Access: [{ Id: 3, ItemName: " User Activity Log", AccessModuleId: 2 }] }
    // ]
  }

  bindUser() {

    this.dataService.getUserType()
      .subscribe(
        result => {
          if(!this.UserType)
          this.UserType=0;
          this.accountTypeList = result.data;
        })

    this.dataService.getUser({})
      .subscribe(
        result => {
          this.userList = result.data;
        })

    this.getUserAccess();
  }


  createUser() {

    if (this.model.UserName == undefined || this.model.UserName == "") {
      this.toastr.error('', 'Please enter your user name.');
      return
    }
    if (this.model.Email == undefined || this.model.Email == "") {
      this.toastr.error('', 'Please enter your email.');
      return
    }
    else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.Email)) {
        this.toastr.error('', 'Please enter valid email.');
        return
      }
    }
    if (this.model.FirstName == undefined || this.model.FirstName == "") {
      this.toastr.error('', 'Please enter your first name.');
      return
    }
    if (this.model.LastName == undefined || this.model.LastName == "") {
      this.toastr.error('', 'Please enter your last name.');
      return
    }
    if (this.model.Password == undefined || this.model.Password == "") {
      this.toastr.error('', 'Please enter your password.');
      return
    }
    if (this.model.rePassword == undefined || this.model.rePassword == "") {
      this.toastr.error('', 'Please enter your re-password.');
      return
    }
    if (this.model.Password != this.model.rePassword) {
      this.toastr.error('', 'Password does not matches.');
      return
    }
    if (this.UserType == undefined || this.UserType == "") {
      this.toastr.error('', 'Please enter account type.');
      return
    }
    console.log( this.Status )
    if ( this.Status == "-1") {
      this.toastr.error('', 'Please enter status.');
      return
    }
    this.model.Phone = 46464646;
    this.model.Fax = 46464646;
    this.model.UserType = this.UserType;
    this.model.Status = this.Status;
    
    this.userAccess.forEach((element) => {
      element.UserAccessInputItems = element.UserAccessItems.filter(function (item) {return item.IsSelected });
  })
    this.model.UserAccess=this.userAccess;
   
    this.dataService.createUser(this.model)
      .subscribe(
        result => {
          if(result.messageCode==2){
            this.toastr.error('', result.message);
          }else{
          this.toastr.success('', result.message);
          this.bindUser();
          this.isShowForm = false;
          this.isShowList = true;
          }
        })
  }

  edit(data: any) {
    this.Id = data.Id
    this.isUpdate = true
    this.resetAccess();
    this.isShowForm = true;
    this.isShowList = false;

    this.model.FirstName = data.FirstName;
    this.model.LastName = data.LastName;
    this.model.Email = data.Email;
    this.model.UserName = data.UserName;
    this.model.Password = data.Password;
    this.model.rePassword = data.Password;
    this.UserType = data.UserType;
    this.Status = data.Status;
  
    this.getUserAccess();
  }
  delete(data: any) {
    let self = this;
    self.Id = data.Id
    self.model.ID = self.Id
    self.model.UpdateBy = 1;

    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this user?')
  
    .then(function(confirmed){
      if(confirmed){
        self.dataService.deleteUser(self.model)
        .subscribe(
          result => {
            self.bindUser();
            self.toastr.success('', result.message);
            self.isShowForm = false;
            self.isShowList = true;
          })
      }
    
    })
    
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  onCustom(event: any) {
    if (event.action == "Edit") {
      this.Id = event.data.Id
      this.isUpdate = true
      this.resetAccess();
      this.isShowForm = true;
      this.isShowList = false;
  
      this.model.FirstName =  event.data.FirstName;
      this.model.LastName =  event.data.LastName;
      this.model.Email =  event.data.Email;
      this.model.UserName =  event.data.UserName;
      this.model.Password =  event.data.Password;
      this.model.rePassword =  event.data.Password;
      this.UserType =  event.data.UserType;
      this.Status =  event.data.Status;
  
      this.getUserAccess();

    }
    if (event.action == "Delete") {
      let self = this;
      self.Id = event.data.Id
      self.model.ID = self.Id
      self.model.UpdateBy = 1;
  
      this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this user?')
    
      .then(function(confirmed){
        if(confirmed){
          self.dataService.deleteUser(self.model)
          .subscribe(
            result => {
              self.data.refresh();
             // self.bindUser();
              self.toastr.success('', result.message);
              self.isShowForm = false;
              self.isShowList = true;
            })
        }
      
      })
      
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      

    }
  }

  getUserAccess(){
    
    this.model.Id= this.Id;
    this.dataService.getUserAccess({Id:this.Id})
    .subscribe(
      result => {
     
        this.userAccess = result.data;
        this.userAccess.forEach((element) => {
          element.IsSelected = element.UserAccessItems.filter(function (item) {return item.IsSelected }).length>0?true:false;
      })
      })
  }
  add() {
    this.isUpdate = false;
    this.isShowForm = true;
    this.isShowList = false;
    this.model.FirstName = "";
    this.model.LastName = "";
    this.model.Email = "";
    this.model.UserName = "";
    this.model.Password = "";
    this.model.rePassword = "";
    this.Status="-1"
    this.UserType=0;
    this.Id="0";
    this.model.ID = "0";
   this.resetAccess();
    this.buttonDisabled = true;
  }

  resetAccess(){
    for (let i = 0; i < this.userAccess.length; i++) {
      this.userAccess[i].IsSelected = false;
  }
  for (let i = 0; i < this.userAccess.length; i++) {
    for (let j = 0; j < this.userAccess[i].UserAccessItems.length; j++) {
        this.userAccess[i].UserAccessItems[j].IsSelected = false; 
    }
  }
  }
  update() {

    // this.model.ID= this.ID
    if (this.model.UserName == undefined || this.model.UserName == "") {
      this.toastr.error('', 'Please enter your user name.');
      return
    }
    if (this.model.Email == undefined || this.model.Email == "") {
      this.toastr.error('', 'Please enter your email.');
      return
    }
    else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.Email)) {
        this.toastr.error('', 'Please enter valid email.');
        return
      }
    }
    if (this.model.FirstName == undefined || this.model.FirstName == "") {
      this.toastr.error('', 'Please enter your first name.');
      return
    }
    if (this.model.LastName == undefined || this.model.LastName == "") {
      this.toastr.error('', 'Please enter your last name.');
      return
    }
    if (this.model.Password == undefined || this.model.Password == "") {
      this.toastr.error('', 'Please enter your password.');
      return
    }
    if (this.model.rePassword == undefined || this.model.rePassword == "") {
      this.toastr.error('', 'Please enter your re-password.');
      return
    }
    if (this.model.Password != this.model.rePassword) {
      this.toastr.error('', 'Password does not matches.');
      return
    }
    if (this.UserType == undefined || this.UserType == "") {
      this.toastr.error('', 'Please enter account type.');
      return
    }
 
    if ( this.Status == "-1") {
      this.toastr.error('', 'Please enter status.');
      return
    }
    this.model.Phone = 46464646;
    this.model.Fax = 46464646;

    this.model.UserType = this.UserType;
    this.model.Status = this.Status;
    this.model.Id = this.Id;
    this.model.ID = this.Id;
    this.userAccess.forEach((element) => {
      element.UserAccessInputItems = element.UserAccessItems.filter(function (item) {return item.IsSelected });
  })
  this.model.UserAccess=this.userAccess;
  
   
    this.dataService.createUser(this.model)
      .subscribe(
        result => {
          this.bindUser();
          this.toastr.success('', result.message);
          this.isShowForm = false;
          this.isShowList = true;
        })

  }
  cancel() {
    this.resetAccess();
    this.isShowForm = false;
    this.isShowList = true;
  }
  
  usernamechange() {

    // alert(this.model.Email)
    this.dataService.checkcheckUserNameAvailability(this.model)
      .subscribe(
        result => {
         
          if (result.data > 0) {
            this.buttonDisabled = true;
            this.toastr.error('', 'User name already exist.');

          
            return
          } else {
            this.buttonDisabled = false;
          }


        })



  }
  onChange(value: any, checked: boolean) {
    if (checked) {
      //debugger;
      this.selectedModule.push(value.ModuleId);
      for (let i = 0; i < this.userAccess.length; i++) {
        for (let j = 0; j < this.userAccess[i].UserAccessItems.length; j++) {
          if (this.userAccess[i].UserAccessItems[j].ModuleId == value.ModuleId) {
            this.userAccess[i].UserAccessItems[j].IsSelected = true;
            this.selectedModuleAccess.push(this.userAccess[i].UserAccessItems[j]);
          }
        }
      }
    } else {

      let index = this.selectedModule.indexOf(value.ModuleId);
      this.selectedModule.splice(index, 1);
      for (let i = 0; i < this.userAccess.length; i++) {
        for (let j = 0; j < this.userAccess[i].UserAccessItems.length; j++) {
          if (this.userAccess[i].UserAccessItems[j].ModuleId == value.ModuleId) {
            this.userAccess[i].UserAccessItems[j].IsSelected = false;
          }
        }
      }

    }
    //console.log(this.userAccess)
  }
  onChangeAccess(value: any, checked: boolean) {
    var isExist = false;
    if (checked) {
      this.selectedModuleAccess.push(value);
      for (let i = 0; i < this.userAccess.length; i++) {
        if (this.userAccess[i].ModuleId == value.ModuleId) {
          this.userAccess[i].IsSelected = true;
        }
      }
    } else {
      let index = this.selectedModuleAccess.indexOf(value);
      this.selectedModuleAccess.splice(index, 1);
     

      for (let i = 0; i < this.selectedModuleAccess.length; i++) {
        if (this.selectedModuleAccess[i].ModuleId == value.ModuleId) {
          isExist = true
        }
      }
      if (isExist == false) {
        for (let i = 0; i < this.userAccess.length; i++) {
          if (this.userAccess[i].ModuleId == value.ModuleId) {
          this.userAccess[i].IsSelected = false;
          }
        }
      }

    }
  }

  findIndexToUpdate(newItem) {
    return newItem.id === this;
  }
  // isValid(): boolean {

  //   if (this.router.url == "/users") {
  //     this.isNewUser = false
  //     return true

  //   }
  //   this.isNewUser = true
  //   return false
  // }
}
